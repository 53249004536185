<template>
  <div class="wrap">
    <div class="content">
      <!-- 遮罩 -->
      <div class="transparentBox" v-if="examineShow"></div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <!-- {{ ruleForm }}////// isbidId
        {{ isbidId }}
        lastid{{ lastid }} -->

        <div class="basicBox">
          <div class="titleFont">基本信息</div>
          <div class="basic">
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item label="产品图片：" prop="picUrl">
                  <el-upload
                    class="chen-image-uploader"
                    :action="uploadPath"
                    :show-file-list="false"
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                  >
                    <img
                      v-if="ruleForm.picUrl"
                      :src="ruleForm.picUrl"
                      class="upload-image"
                    />
                    <i v-else class="el-icon-plus image-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item
                  class="form-item"
                  label="订单模式："
                  prop="orderProcess"
                >
                  <!-- <el-radio-group v-model="ruleForm.orderProcess">
                    <el-radio label="HK">先货后款</el-radio>
                    <el-radio label="KH">先款后货</el-radio>
                  </el-radio-group> -->
                  <el-select
                    class="input-width"
                    v-model="ruleForm.orderProcess"
                    placeholder="请选择"
                    size="mini"
                    @change="changeOrderProcess(ruleForm.orderProcess)"
                  >
                    <el-option
                      v-for="item in paymentmethod.orderProcess"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="产品名称：" prop="prodName">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.prodName"
                    placeholder="请输入产品名称"
                    size="mini"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item
                  class="form-item"
                  label="产品类型："
                  prop="dtsCategoryId"
                >
                  <el-cascader
                    class="input-width"
                    v-model="ruleForm.dtsCategoryId"
                    :options="categoryList"
                    size="mini"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="规格型号：" prop="prodModels">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.prodModels"
                    placeholder="请输入规格型号"
                    size="mini"
                    maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item label="数量：" prop="amount">
                  <el-row type="flex" justify="space-between">
                    <el-col :span="16">
                      <el-input
                        class="input-width"
                        v-model="ruleForm.amount"
                        maxlength="10"
                        placeholder="请输入数量"
                        size="mini"
                        @input.native="valueAmount($event, 'amount')"
                      ></el-input>
                    </el-col>
                    <el-col :span="7">
                      <el-select
                        class="input-width"
                        v-model="ruleForm.units"
                        placeholder="单位"
                        allow-create
                        filterable
                        default-first-option
                        size="mini"
                      >
                        <el-option
                          v-for="(item, index) in unitList"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                      <!-- <el-input class="input-width" v-model="ruleForm.units" placeholder="单位" size="mini"></el-input> -->
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  class="form-item"
                  label="合同模板："
                  prop="contractinfono"
                >
                  <el-select
                    v-model="ruleForm.contractinfono"
                    placeholder="请选择合同模板"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      id="contract-id"
                      v-for="item in contractList"
                      :key="item.tContractTextNo"
                      :label="item.tContractTextName"
                      :value="item.tContractTextNo"
                    >
                      <div class="contract-box">
                        <div class="contract-left">
                          <span class="name">{{ item.tContractTextName }}</span>
                          <span class="num">{{ item.tContractTextNo }}</span>
                        </div>
                        <div
                          class="contract-rigth"
                          @click="showContractDialog(item.id)"
                          style="color: #8492a6"
                        >
                          查看
                        </div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- 查看合同模板 -->
                <el-dialog
                  title="合同模板"
                  :visible.sync="contractDialogVisible"
                  width="700px"
                >
                  <div v-html="contractTemplate"></div>
                </el-dialog>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item label="合同编号：" prop="contractNumber">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.contractNumber"
                    maxlength="50"
                    placeholder="请输入合同编号"
                    size="mini"
                    onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                    @input.native="valueNumber($event, 'contractNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="basicBox">
          <div class="titleFont">起拍信息</div>
          <div class="basic">
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item label="开始时间：" prop="startTime">
                  <el-date-picker
                    size="mini"
                    class="input-width"
                    v-model="ruleForm.startTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="option"
                    placeholder="请选择开始时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="起拍价(元)：" prop="upPrice">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.upPrice"
                    :maxlength="13"
                    autocomplete="off"
                    placeholder="请输入起拍价"
                    size="mini"
                    @input.native="keyup($event, 'upPrice')"
                    @blur="blurmethod($event, 'upPrice')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item label="最低价(元)：" prop="bottomPrice">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.bottomPrice"
                    :maxlength="13"
                    autocomplete="off"
                    placeholder="请输入最低价"
                    size="mini"
                    @input.native="keyup($event, 'bottomPrice')"
                    @blur="blurmethod($event, 'bottomPrice')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="持续时间(分钟)：" prop="continueTime">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.continueTime"
                    placeholder="请输入持续时间"
                    size="mini"
                    :maxlength="5"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item label="降价策略(元/分钟)：" prop="failTactics">
                  <el-input
                    class="input-width"
                    v-model="ruleForm.failTactics"
                    :maxlength="13"
                    autocomplete="off"
                    placeholder="请输入降价策略"
                    size="mini"
                    @input.native="keyup($event, 'failTactics')"
                    @blur="blurmethod($event, 'failTactics')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="basicBox">
          <div class="titleFont">发票信息</div>
          <div class="basic">
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item
                  class="form-item"
                  label="发票类型："
                  prop="invoiceType"
                >
                  <el-radio-group v-model="ruleForm.invoiceType">
                    <el-radio label="0">专用发票</el-radio>
                    <el-radio disabled label="1">普通发票</el-radio>
                    <!--                <el-radio label="1">区块链发票</el-radio>
                <el-radio label="1">通用机打电子发票</el-radio>-->
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item
                  label="适用税率(%)："
                  prop="arbAdditionInfo.taxRate"
                >
                  <el-input
                    class="input-width"
                    v-model="ruleForm.arbAdditionInfo.taxRate"
                    placeholder="请输入税率"
                    size="mini"
                    @input.native="valueChange($event, 'taxRate')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item
                  class="form-item"
                  label="开票时间："
                  prop="arbAdditionInfo.invsStatus"
                >
                  <el-radio-group v-model="ruleForm.arbAdditionInfo.invsStatus">
                    <el-radio label="KQ">付款前开发票</el-radio>
                    <el-radio label="KH">付款后开发票</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="10">
                <el-form-item
                  class="form-item-invsStatus"
                  prop="arbAdditionInfo.invsDay"
                >
                  <div
                    style="font-size: 14px"
                    :style="{
                      color:
                        ruleForm.arbAdditionInfo.invsStatus == 'KH'
                          ? '#000'
                          : '#c0c4cc',
                    }"
                  >
                    付款之日起【<el-input
                      class="payinput-width"
                      :disabled="
                        this.ruleForm.arbAdditionInfo.invsStatus == 'KH'
                          ? false
                          : true
                      "
                      v-model="ruleForm.arbAdditionInfo.invsDay"
                      size="mini"
                    ></el-input
                    >】个工作日内开票
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-form-item>
            <div class="btnBoxStyle">
          <el-button class="btnstyle" type="primary" @click="next('ruleForm')"
            >下一步</el-button
          >
            </div>
        </el-form-item>
      </el-form>
      <!-- 
      <div class="submit-state" v-if="showState">
        <div class="state-icon">
          <i class="el-icon-success"></i>
        </div>
        <p class="state-msg">提交成功</p>
        <p class="state-desc">
          您的竞卖信息已提交成功，等待平台审核，请及时查询
        </p>
        <router-link to="./MyBidding">
          <el-button plain>查看我的竞卖</el-button>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { categoryList } from "../../../Home/api";
import {
  addsAutionInfo,
  uploadPath,
  autionInfoById,
  myAutionInfoUpdate,
  categoryAll,
  getAUContract,
  getContractInfo,
} from "../../../../api";

import tinymceEditor from "@/components/tinymceEditor.vue";

export default {
  components: {
    "tinymce-editor": tinymceEditor,
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isbidId: {
      type: Number,
      default: () => {
        return {};
      },
    },
    lastid: {
      type: Number,
      default: () => {
        return {};
      },
    },
       examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  // computed: {
  //   ruleForm: function () {
  //     return this.ruleForm;
  //   },
  // },
  watch: {
    "ruleForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--3333" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.ruleForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.ruleForm.arbAdditionInfo.invsStatus = "KH";
      }
    },

    "ruleForm.arbAdditionInfo.paySet": function (newVal, oldVal) {
      console.log(newVal + "--paySet" + oldVal);
      if (newVal == "FQ") {
        this.ruleForm.arbAdditionInfo.paySetPort = "";
      }

      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.ruleForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.paySetList = true;
      }
    },

    "ruleForm.arbAdditionInfo.deliverSet": function (newVal, oldVal) {
      console.log(newVal + "--deliverSet" + oldVal);

      if (newVal == "FQ") {
        this.ruleForm.arbAdditionInfo.deliverSetPort = "";
      }

      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.ruleForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.deliverSetList = true;
      }
    },

    "ruleForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      console.log(newVal + "--222222" + oldVal);
      if (newVal == "SH" && oldVal == "ZT") {
        this.ruleForm.arbAdditionInfo.deliverModeDay = "";
      }
    },
    "ruleForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.ruleForm.arbAdditionInfo.qualityTerm = "";
      }
    },
    "ruleForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      if (newVal == "KQ" && oldVal == "KH") {
        this.ruleForm.arbAdditionInfo.invsDay = "";
      }
    },
  },
  data() {
    var validateAmount = (rule, value, callback) => {
      if (!value && this.ruleForm.units == "") {
        return callback(new Error("请输入数量和单位"));
      } else if (!value) {
        return callback(new Error("请输入数量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 6
      ) {
        return callback(new Error("最多保留六位小数"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else if (value.indexOf(".") !== -1) {
        return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
      } else if (value.length !== 1 && value[0] == 0) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else {
        callback();
      }
    };
    var validateUpPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入起拍价"));
      } else if (Number(value) >= 10000000000) {
        return callback(new Error("起拍价不大于 10000000000 元"));
      } else if (
        this.ruleForm.bottomPrice != "" &&
        Number(value) < Number(this.ruleForm.bottomPrice)
      ) {
        return callback(new Error("起拍价不得低于最低价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else {
        callback();
      }
    };
    var validateBottomPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入最低价"));
      } else if (Number(value) >= 10000000000) {
        return callback(new Error("最低价不大于 10000000000 元"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else {
        if (this.ruleForm.upPrice != "") {
          this.$refs.ruleForm.validateField("upPrice");
        }
        callback();
      }
    };
    var validateFailTactics = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入降价策略"));
      } else if (Number(value) >= 10000000000) {
        return callback(new Error("降价策略不大于 10000000000 元"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else {
        callback();
      }
    };
    var validateStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value).getTime() < Date.now() - 1000) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else {
        callback();
      }
    };
    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };

    var validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("小数位数不允许大于2位")); //小数点后两位
      } else if (value.indexOf(".") != -1 && value.split(".")[0] == "") {
        callback(new Error("请输入正确的数字")); //小数点后两位
      } else if (value.indexOf(".") != -1 && value.split(".")[1] == "") {
        callback(new Error("请输入正确的数字")); //小数点后无数字
      } else {
        callback();
      }
    };
    var validatePayAfterData = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 30) {
        return callback(new Error("请输入 1 ~ 30之间日期"));
      } else {
        callback();
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.ruleForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.ruleForm.payMode.length == 1 &&
        this.ruleForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.ruleForm.payMode.indexOf("CDHP"));
      if (
        this.ruleForm.payMode.indexOf("YHZZ") !== -1 &&
        this.ruleForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.ruleForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.ruleForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.ruleForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.ruleForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.ruleForm.payMode.length == 1 &&
        this.ruleForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.ruleForm.payMode.indexOf("YHZZ") !== -1 &&
        this.ruleForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.ruleForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.ruleForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.ruleForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.ruleForm.payMode.indexOf("YHZZ") == -1 &&
        this.ruleForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.ruleForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.ruleForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }

      let newList = [];

      this.ruleForm.payDeliverRateFkList.map((item) => {
        newList.push(item.termIndex);
      });
      console.log("   this.newListFk", newList);

      for (var i = 0; i < newList.length; i++) {
        console.log("newList[i]", newList[i]);
        if (newList.indexOf(newList[i]) != -1) {
          console.log("找到了", newList.indexOf(newList[i]));
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }

      // let newList = [];

      this.ruleForm.payDeliverRateJhList.map((item) => {
        this.newListJh.push(item.termIndex);
      });

      for (var i = 0; i < this.newListJh.length; i++) {
        if (this.newListJh.indexOf(this.newListJh[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };

    var validatefqTermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };
    var validateInvsDay = (rule, value, callback) => {
      if (this.ruleForm.arbAdditionInfo.invsStatus == "KH") {
        if (!value || isNaN(value) || value < 0 || value > 180) {
          this.ruleForm.arbAdditionInfo.invsDay = "";
          return callback(new Error("请输入 1 ~ 180之间日期"));
        } else if (value.toString().indexOf(".") != -1) {
          this.ruleForm.arbAdditionInfo.invsDay = "";
          callback(new Error("请输入1 ~ 180之间的正整数")); //防止输入多个小数点
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    //产品名称
    var validateProdName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入产品名称"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    // 规格型号
    var validateProdModels = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入规格型号"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };
    //持续时间
    var validateContinueTime = (rule, value, callback) => {
      if (!value || isNaN(value) || value < 0 || value > 10000) {
        return callback(new Error("请输入 1~10000 之间的持续时间"));
      } else if (
        value.toString().indexOf("'") !== -1 ||
        value.toString().indexOf('"') !== -1
      ) {
        return callback(new Error("禁止输入英文单双引号"));
      } else if (!value || isNaN(value) || value <= 0) {
        return callback(new Error("请输入正整数"));
      } else if (
        value.toString().indexOf(".") !== -1 ||
        value.toString().indexOf('"') !== -1
      ) {
        return callback(new Error("请输入正整数"));
      }
      return callback();
    };
    //合同编号的校验
    var validateContractNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入合同编号"));
      } else if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1) {
        return callback(new Error("禁止输入英文单双引号"));
      }
      return callback();
    };

    return {
      deliverSetList: false,
      paySetList: false,
      FkArrange: "",
      newListFk: [],
      newListJh: [],
      contractTemplate: "",
      contractDialogVisible: false,
      contractList: [],
      contractInfo: [],
      uploadPath,
      pageStete: "publish",
      categoryList: [],
      order: 1,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      // ruleForm: {
      //   id: "",
      //   picUrl: "", //产品图片
      //   prodName: "", //产品名称
      //   dtsCategoryId: "", //产品类型
      //   prodModels: "", //规格型号
      //   amount: "", //数量
      //   units: "", //单位
      //   upPrice: "", //起拍价
      //   bottomPrice: "", //最低价
      //   failTactics: "", //降价策略
      //   startTime: "", //开始时间
      //   continueTime: "", //持续时间
      //   autionDetails: "", //竞卖说明
      //   contractinfono: "",
      //   orderProcess: "", //订单模式
      //   payMode: [], //付款方式
      //   bankBillTerm: "", //承兑汇票期限
      //   testlist: [],
      //   //付款分期表格
      //   payDeliverRateFkList: [
      //     // {
      //     //   termIndex: "",
      //     //   rate: "",
      //     //   date: "", //分期付款开始时间
      //     //   payMode: "",
      //     //   type: "FK",
      //     // },
      //   ],
      //   //交货分期表格
      //   payDeliverRateJhList: [
      //     // {
      //     //   termIndex: "",
      //     //   rate: "",
      //     //   date: "", //交货付款开始时间
      //     //   // payMode: "",
      //     //   type: "JH",
      //     // },
      //   ],

      //   arbAdditionInfo: {
      //     fqTermIndex: "",
      //     type: "AU", //竞卖
      //     taxRate: "", // 使用税率
      //     invsStatus: "", // 开票时间
      //     paySet: "", //付款安排
      //     deliverSet: "", //交付安排
      //     deliverMode: "", // 交货方式
      //     packageMode: "", //包装方式
      //     checkMode: "", //验收条件
      //     qualityMode: "", //质保方式
      //     qualityTerm: "", //质保的日期
      //     paySetPort: "", // 付款安排数据
      //     deliverSetPort: "", // 交付安排数据

      //     invsDay: "", //付款后开发票日期
      //     deliverModeDay: "", //买家自提的日期
      //     bankBillTerm: "", //承兑汇票期限
      //     payMode: "",
      //     //------------------------
      //     paySetDay: "", //付款安排一次性付款日期

      //     //---------------------------
      //     deliverSetDay: "", //交货安排一次性交货日期

      //     //-----------------------------
      //     payPercent: "", // 付款占比
      //     yhzzPercentage: "", //银行转账百分比
      //     cdhpPercentage: "", //承兑汇票百分比
      //   },
      // },
      publishForm: {
        productName: "",
        categoryId: "",
        productSpec: "",
        maxPrice: "",
        reqNum: "",
        unit: "",
        picUrl: "",
        startTime: "",
        validDate: "",
        dateTime: "",
        date: "",
        time: "",
        reqRemark: "",
        addressId: "",
        deliverDay: "",
        stepPrice: "",
        // forceShow: '',
        contractInfo: "",
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        taxRate: "", // 使用税率
        invsStatus: "", // 票据时间
        paySet: "", //付款安排
        paySetPort: "", // 付款安排数据
        invsDay: "", //付款后开发票日期
      },
      rules: {
        picUrl: [
          {
            required: true,
            message: "请上传产品图片",
            trigger: ["blur", "change"],
          },
        ],
        prodName: [
          { required: true, validator: validateProdName, trigger: "blur" },
        ],
        dtsCategoryId: [
          { required: true, message: "请选择产品类型", trigger: "change" },
        ],
        prodModels: [
          { required: true, validator: validateProdModels, trigger: "blur" },
        ],
        amount: [
          { required: true, validator: validateAmount, trigger: "blur" },
        ],
        upPrice: [
          { required: true, validator: validateUpPrice, trigger: "blur" },
        ],
        bottomPrice: [
          { required: true, validator: validateBottomPrice, trigger: "blur" },
        ],
        failTactics: [
          { required: true, validator: validateFailTactics, trigger: "blur" },
        ],
        startTime: [
          {
            required: true,
            validator: validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        continueTime: [
          { required: true, validator: validateContinueTime, trigger: "blur" },
        ],
        contractinfono: [
          {
            required: true,
            message: "请选择合同模板",
            trigger: ["blur", "change"],
          },
        ],
        orderProcess: [
          { required: true, message: "请选择订单模式", trigger: "change" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        payMode: [
          {
            required: true,
            // message: "请选择付款方式",
            validator: validatePayMode,
            trigger: ["blur", "change"],
          },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "change" },
        ],
        contractNumber: [
          {
            required: true,
            validator: validateContractNumber,
            trigger: "blur",
          },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validateInvsDay,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        // "arbAdditionInfo.acceptanceQualifiedDate": [
        //   {
        //     required: true,
        //     validator: validatePayAfterData,
        //     trigger: ["blur", "change"],
        //   },
        // ],
        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validatePayAfterData,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,

            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["change"],
          },
        ],
      },
      productDetail: null,
      paymentmethod: {
        orderProcess: [
          {
            value: "HK",
            label: "先货后款",
          },
          {
            value: "KH",
            label: "先款后货",
          },
        ],
        payMode: "",
        bankBillTerm: "",
        payListOption: [
          {
            value: "1",
            label: "第1期",
          },
          {
            value: "2",
            label: "第2期",
          },
          {
            value: "3",
            label: "第3期",
          },
          {
            value: "4",
            label: "第4期",
          },
          {
            value: "5",
            label: "第5期",
          },
          {
            value: "6",
            label: "第6期",
          },
          {
            value: "7",
            label: "第7期",
          },
          {
            value: "8",
            label: "第8期",
          },
          {
            value: "9",
            label: "第9期",
          },
          {
            value: "10",
            label: "第10期",
          },
          {
            value: "11",
            label: "第11期",
          },
          {
            value: "12",
            label: "第12期",
          },
          {
            value: "13",
            label: "第13期",
          },
          {
            value: "14",
            label: "第14期",
          },
          {
            value: "15",
            label: "第15期",
          },
          {
            value: "16",
            label: "第16期",
          },
          {
            value: "17",
            label: "第17期",
          },
          {
            value: "18",
            label: "第18期",
          },
          {
            value: "19",
            label: "第19期",
          },
          {
            value: "20",
            label: "第20期",
          },
          {
            value: "21",
            label: "第21期",
          },
          {
            value: "22",
            label: "第22期",
          },
          {
            value: "23",
            label: "第23期",
          },
          {
            value: "24",
            label: "第24期",
          },
        ],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
      typeList: [
        {
          id: "铝原料",
          name: "铝原料",
        },
      ],
      showState: false,
      option: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        },
      },
    };
  },
  created() {
    this.$store.commit("changeMenuType", "SELL");
    this.getAllCategory();
    if (this.$route.query.id !== "" || this.$route.query.id !== undefined) {
      this.initAutionInfoById();
    }

    this.pageStete = this.$route.query.id ? "edit" : "publish";
    this.getAUContract();
  },
  methods: {
    valueChange(e, val) {
      console.log(e.target.value);
      e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;

      // 只能是数字和小数点，不能是其他输入
      e.target.value = e.target.value.replace(reg, "");

      // 保证第一位只能是数字，不能是点
      e.target.value = e.target.value.replace(/^\./g, "");
      // 小数只能出现1位
      e.target.value = e.target.value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // 小数点后面保留2位
      e.target.value = e.target.value.replace(
        /^(\-)*(\d+)\.(\d\d).*$/,
        "$1$2.$3"
      );
      //赋值
      this.ruleForm.arbAdditionInfo[val] = e.target.value;
    },
    keyup(e, val) {
      //限制10位整数 与两位小数的正则规则
      if (e.target.value[0] === ".") {
        e.target.value = e.target.value.replace(/^\./g, "");
        this.ruleForm[val] = e.target.value;
      } else if (e.target.value[0] === "0" && /\d/.test(e.target.value[1])) {
        e.target.value = e.target.value.substring(1);
      } else if (e.target.value.includes(".")) {
        const arr = e.target.value.split(".");
        if (arr[0].length > 10) {
          e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`;
          this.ruleForm[val] = e.target.value;
        } else {
          e.target.value = e.target.value.replace(/[^(\d\.)]/g, "");
          e.target.value = e.target.value.replace(
            /^(\d{1,12}\.?\d{0,2}).*/,
            "$1"
          );
          this.ruleForm[val] = e.target.value;
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        e.target.value = e.target.value.replace(/^(\d{0,10}).*/, "$1");
        this.ruleForm[val] = e.target.value;
      }
    },
    blurmethod(e, val) {
      if (e.target.value[e.target.value.length - 1] === ".") {
        e.target.value = e.target.value.substring(0, [
          e.target.value.length - 1,
        ]);
        this.ruleForm[val] = e.target.value;
      }

      //  console.log( e.target.value.length==12 &&e.target.value.indexOf('.')==-1);
      //  console.log(e.target.value.indexOf('.')==-1);
    },

    valueAmount(e, val) {
      e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/g, "");
      const reg = /[^\d.]/g;

      // 只能是数字和小数点，不能是其他输入
      e.target.value = e.target.value.replace(reg, "");

      // 保证第一位只能是数字，不能是点
      e.target.value = e.target.value.replace(/^\./g, "");
      // 小数只能出现1位
      e.target.value = e.target.value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // 小数点后面保留2位
      e.target.value = e.target.value.replace(
        /^(\-)*(\d+)\.(\d\d\d\d).*$/,
        "$1$2.$3"
      );
      //赋值
      this.ruleForm[val] = e.target.value;
    },

    valueNumber(e, val) {
      e.target.value = e.target.value.replace(/[\u4E00-\u9FA5]/g, "");
      //赋值
      this.ruleForm[val] = e.target.value;
    },
    changeOrderProcess(value) {
      console.log(value);
      if (value == "HK") {
        this.ruleForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (value == "KH") {
        this.ruleForm.arbAdditionInfo.invsStatus = "KH";
      }
    },
    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.ruleForm.orderProcess == "KH") {
        this.ruleForm.arbAdditionInfo.deliverSetPort = "SK";
      }
    },
    paySetMethod(newVal) {
      console.log("paySetMethod", newVal);
      if (newVal == "QK" && this.ruleForm.orderProcess == "HK") {
        this.ruleForm.arbAdditionInfo.paySetPort = "YS";
      }
    },

    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      this.ruleForm.payDeliverRateFkList = [];
      this.ruleForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        let obj = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          payMode: "",

          type: "FK",
        };

        this.ruleForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };
        // this.ruleForm.payDeliverRateJhList = [];

        this.ruleForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.ruleForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.ruleForm.payDeliverRateFkList[val].termIndex = value;
    },

    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.contractTextModel;
        }
      });

      this.contractDialogVisible = true;
    },
    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },
    // 图片上传之前
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 图片上传成功
    handleSuccess(res, file) {
      if (res.errno == 0) {
        this.ruleForm.picUrl = res.data.url;
        this.$notify.success("上传成功");
        this.$refs.ruleForm.validateField("picUrl");
      }
    },
    // 立即发布
    next(formName) {
      var _this = this;

       if (this.examineShow == true) {
        this.$emit("nextShow");
      } else {
      _this.$refs[formName].validate((valid) => {
        console.log("vaild", valid);

        if (valid) {

      // if (localStorage.getItem('userPermission').indexOf('sell:au:push') ==-1) {
      //   return this.$message.info("暂无修改权限，请联系管理员");
      // }
          this.$emit("next", JSON.parse(JSON.stringify(this.ruleForm)));
          if (!this.ruleForm.units) {
            return this.$message.warning("请选择单位");
          }

          this.ruleForm.payMode = this.ruleForm.payMode
            .toString()
            .replaceAll(",", "|");

          this.ruleForm.arbAdditionInfo.payMode = this.ruleForm.payMode;

          //处理百分比
          let YHZZper = this.ruleForm.arbAdditionInfo.yhzzPercentage;
          let CDHPper = this.ruleForm.arbAdditionInfo.cdhpPercentage;
          let YHHPper = this.ruleForm.arbAdditionInfo.yhhpPercentage;

          if (this.ruleForm.payMode == [] || this.ruleForm.payMode == "") {
            this.ruleForm.arbAdditionInfo.payPercent = "";
          } else if (
            this.ruleForm.payMode.length == 14 ||
            this.ruleForm.payMode.length == 9
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            if (CDHPper == "") {
              CDHPper = "0";
            }
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + CDHPper + "|" + YHHPper;

            console.log("payPercent1");
          } else if (
            this.ruleForm.payMode.length == 4 &&
            this.ruleForm.payMode.indexOf("YHZZ") !== -1
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + "0" + "|" + "0";
          } else if (
            this.ruleForm.payMode.length == 4 &&
            this.ruleForm.payMode.indexOf("CDHP") !== -1
          ) {
            if (CDHPper == "") {
              CDHPper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              "0" + "|" + CDHPper + "|" + "0";
          } else if (
            this.ruleForm.payMode.length == 4 &&
            this.ruleForm.payMode.indexOf("YHHP") !== -1
          ) {
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              "0" + "|" + "0" + "|" + YHHPper;
          }

          this.ruleForm.arbAdditionInfo.bankBillTerm =
            this.ruleForm.bankBillTerm;

          let axiosData = Object.assign({}, _this.ruleForm);
          axiosData.startTime = axiosData.startTime;
          axiosData.dtsCategoryId = axiosData.dtsCategoryId[1];
          let NowAxiosData = { ...axiosData };
          // debugger
          if (axiosData.id == null || axiosData.id == "") {
            if (this.isbidId == 0) {
              addsAutionInfo({ ...NowAxiosData, steps: "0" }).then((res) => {
                if (res.errno == 0) {
                  // this.$message({
                  //   type: "success",
                  //   message: "发布成功！",
                  // });
                  this.bidId = res.data.auctionId;
                  this.$emit("getBidId", this.bidId);
                }
              });
            } else {
              myAutionInfoUpdate({
                ...NowAxiosData,
                id:
                  this.isbidId == 0 && this.$route.query.id !== undefined
                    ? this.$route.query.id
                    : this.isbidId,
                steps: "0",
              }).then((res) => {
                if (res.errno == 0) {
                  // this.$message({
                  //   type: "success",
                  //   message: "修改成功！",
                  // });

                  if (this.$route.query.id !== undefined) {
                    this.bidId = this.$route.query.id;
                    this.$emit("getBidId", this.bidId);
                  } else {
                    console.log("lastid1", this.lastid);
                    this.bidId = this.lastid;
                    this.$emit("getBidId", this.bidId);
                  }
                }
              });
            }
          } else {
            myAutionInfoUpdate({
              ...NowAxiosData,
              id:
                this.isbidId == 0 && this.$route.query.id !== undefined
                  ? this.$route.query.id
                  : this.isbidId,
              steps: "0",
            }).then((res) => {
              if (res.errno == 0) {
                // this.$message({
                //   type: "success",
                //   message: "修改成功！",
                // });

                if (this.$route.query.id !== undefined) {
                  this.bidId = this.$route.query.id;
                  this.$emit("getBidId", this.bidId);
                } else {
                  console.log("lastid2", this.lastid);
                  this.bidId = this.lastid;
                  this.$emit("getBidId", this.bidId);
                }
              }
            });
          }
        }
      });
      }
    },

    //初始化发布竞卖页面 回显
    initAutionInfoById() {
      if (this.$route.query.id != null) {
        autionInfoById({
          id: this.$route.query.id,
        }).then((res) => {
          if (res.errno == 0) {
            // this.ruleForm = res.data;
            Object.keys(this.ruleForm).forEach((key) => {
              this.ruleForm[key] = res.data[key];
            });

            this.ruleForm.arbAdditionInfo.taxRate =
              res.data.arbAdditionInfo.taxRate.toString();
            // this.ruleForm.payMode = res.data.payMode.split("|");
            if (
              res.data.arbAdditionInfo.payMode &&
              res.data.arbAdditionInfo.payMode.indexOf("|") != -1
            ) {
              this.ruleForm.payMode =
                res.data.arbAdditionInfo.payMode.split("|");
            } else {
              // console.log("res.data.payMode", [res.data.payMode]);
              console.log("找不到||||", res.data);
              if (res.data.arbAdditionInfo.payMode == "") {
                this.ruleForm.payMode = [];
              } else {
                this.ruleForm.payMode = [res.data.arbAdditionInfo.payMode];
              }
            }

            if (
              res.data.arbAdditionInfo.fqTermIndex !== "" &&
              res.data.arbAdditionInfo.paySet == "FQ"
            ) {
              this.paySetList = true;
            }
            if (
              res.data.arbAdditionInfo.fqTermIndex !== "" &&
              res.data.arbAdditionInfo.deliverSet == "FQ"
            ) {
              this.deliverSetList = true;
            }

            if (
              res.data.arbAdditionInfo.qualityTerm !== "" &&
              res.data.arbAdditionInfo.qualityTerm !== undefined
            ) {
              res.data.arbAdditionInfo.qualityTerm =
                res.data.arbAdditionInfo.qualityTerm.toString();
            }

            //百分比初始化
            if (res.data.arbAdditionInfo.payPercent == "") {
              this.$set(this.ruleForm.arbAdditionInfo, "yhzzPercentage", "");
              this.$set(this.ruleForm.arbAdditionInfo, "cdhpPercentage", "");
              this.$set(this.ruleForm.arbAdditionInfo, "yhhpPercentage", "");
            } else {
              let percentageArr =
                res.data.arbAdditionInfo.payPercent.split("|");
              console.log("percentageArr", percentageArr);
              if (percentageArr[0] == "0" && percentageArr[0] !== undefined) {
                this.ruleForm.arbAdditionInfo.yhzzPercentage = "0";
                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "cdhpPercentage",
                  percentageArr[1]
                );

                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "yhhpPercentage",
                  percentageArr[2]
                );
              } else if (
                percentageArr[1] == "0" &&
                percentageArr[1] !== undefined
              ) {
                console.log("bbbbbb");

                this.ruleForm.arbAdditionInfo.cdhpPercentage = "0";
                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "yhhpPercentage",
                  percentageArr[2]
                );

                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "yhzzPercentage",
                  percentageArr[0]
                );
              } else if (
                percentageArr[2] == "0" &&
                percentageArr[2] !== undefined
              ) {
                console.log("cccccc");

                this.ruleForm.arbAdditionInfo.yhhpPercentage = "0";
                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "cdhpPercentage",
                  percentageArr[1]
                );

                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "yhzzPercentage",
                  percentageArr[0]
                );
              } else {
                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "cdhpPercentage",
                  percentageArr[1]
                );

                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "yhzzPercentage",
                  percentageArr[0]
                );
                this.$set(
                  this.ruleForm.arbAdditionInfo,
                  "yhhpPercentage",
                  percentageArr[2]
                );
              }
            }

            if (
              res.data.arbAdditionInfo.paySet == "QK" &&
              res.data.arbAdditionInfo.deliverSet == "FQ"
            ) {
              this.ruleForm.payDeliverRateFkList =
                res.data.payDeliverRateJhList.map((item) => {
                  return {
                    termIndex: item.termIndex,
                    rate: "",
                    date: "",
                    payMode: "",
                    type: "FK",
                  };
                });
            }

            if (
              res.data.arbAdditionInfo.deliverSet == "QK" &&
              res.data.arbAdditionInfo.paySet == "FQ"
            ) {
              this.ruleForm.payDeliverRateJhList =
                res.data.payDeliverRateFkList.map((item) => {
                  return {
                    termIndex: item.termIndex,
                    rate: "",
                    date: "",
                    // payMode: "",
                    type: "JH",
                  };
                });
            }

            if (
              res.data.arbAdditionInfo.deliverSet == "QK" &&
              res.data.arbAdditionInfo.paySet == "QK"
            ) {
              this.ruleForm.payDeliverRateFkList = [
                {
                  termIndex: "",
                  rate: "",
                  date: "",
                  payMode: "",

                  type: "FK",
                },
              ];

              this.ruleForm.payDeliverRateJhList = [
                {
                  termIndex: "",
                  rate: "",
                  date: "",
                  payMode: "",

                  type: "JH",
                },
              ];
              // }
            }
          }
        });
      }
    },

    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },
    getAUContract() {
      getAUContract({
        // page: 1,
        // limit: 100
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-col-10 {
  margin-top: 10px !important;
}
/deep/ .el-input--mini .el-input__inner {
  height: 38px;
  line-height: 28px;
}

// /deep/ .next {
//   width: 150px;
//   margin-left: 350px !important;
// }
/deep/ .el-form-item__label {
  font-size: 15px !important;
  padding: 0;
}
/deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  text-align: center;
}

.wrap {
  background: #fff;

  .el-select,
  .el-input {
    width: 100%;
  }

  .basicBox {
    position: relative;
    margin-top: 20px;
    padding-right: 45px;
    .basic {
      border-top: 1px solid #eeeeee;
      padding-top: 38px;
      z-index: 1;
    }
    .titleFont {
      position: absolute;
      top: -15px;

      font-size: 18px;
      color: #a7b1c1;
      width: 80px;
      background-color: #fff;
      z-index: 2;
      margin-bottom: -5px;
    }
  }
  .form-item-invsStatus {
    // margin-right: 200px;
    margin-left: -84px;
    margin-bottom: 30px;
    // width: 34%;
  }
  .content {
    position: relative;

    padding: 50px 0 0 200px;
    
    position: relative;
    .btnBoxStyle {
      margin-bottom: 30px;
      .btnstyle {
        position: absolute;
        right: 60%;
        z-index: 15;
        width: 140px;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .chen-image-uploader {
      .image-uploader-icon {
        font-size: 36px;
        color: #c8c9cf;
        width: 175px;
        height: 150px;
        line-height: 150px;
        text-align: center;
        background-color: #f6f7fc;
      }
      .upload-image {
        width: 90px;
        height: 90px;
        display: block;
      }
    }
    .payinput-width {
      width: 70px;
    }
    .buyer-width {
      width: 100px;
    }
    .addPayList {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      margin: 0 0 30px 420px;
    }
    .reducePayList {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      margin-left: 30px;
    }
    .submit-state {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .state-icon {
        font-size: 60px;
        margin-top: -100px;

        .el-icon-success {
          color: #55c176;
        }
      }
      .state-msg {
        font-weight: bold;
        font-size: 18px;
        margin-top: 0px;
      }
      .state-desc {
        font-weight: bold;
        font-size: 15px;
        margin-top: 18px;
        margin-bottom: 30px;
      }
    }
  }
  .input-width {
    width: 100%;
  }
  .input-width-table {
    width: 100%;
    margin-top: 20px;
  }
  .capital {
    margin-top: 15px;
  }
}

/deep/ .el-table__row {
  .el-form-item {
    .el-form-item__content {
      margin-left: 0px !important;
    }
  }
}
</style>
<style lang="less">
.chen-image-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }
}

/deep/ #contract-id {
  white-space: normal;
  overflow: visible;
  height: 70px;
  margin-top: 10px;
  line-height: 20px;
  z-index: 9999;
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      display: block;
      word-wrap: break-word;
      top: 5px;
      left: 0;
    }
    .num {
      display: block;
      bottom: -48px;
      left: 0;
    }
  }
}
.tag {
  color: #dc143c;
  font-size: 14px;
}
</style>
