var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "150px",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { staticStyle: { width: "700px" }, attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: { label: "付款方式：", prop: "payMode" },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.payModeMethod },
                            model: {
                              value: _vm.ruleForm.payMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "payMode", $$v)
                              },
                              expression: "ruleForm.payMode",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: "YHZZ" },
                                model: {
                                  value: _vm.ruleForm.payMode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payMode", $$v)
                                  },
                                  expression: "ruleForm.payMode",
                                },
                              },
                              [_vm._v("银行转账")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: "CDHP" },
                                model: {
                                  value: _vm.ruleForm.payMode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payMode", $$v)
                                  },
                                  expression: "ruleForm.payMode",
                                },
                              },
                              [_vm._v("商业承兑汇票")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: { label: "YHHP" },
                                model: {
                                  value: _vm.ruleForm.payMode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payMode", $$v)
                                  },
                                  expression: "ruleForm.payMode",
                                },
                              },
                              [_vm._v("银行承兑汇票")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        class: {
                          "form-item": "true" == "true",
                          yhzzStyle:
                            this.ruleForm.payMode.indexOf("YHZZ") == -1,
                        },
                        attrs: { prop: "arbAdditionInfo.yhzzPercentage" },
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                this.ruleForm.payMode.indexOf("YHZZ") !== -1
                                  ? "#000"
                                  : "#c0c4cc",
                            },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v("银行转账比例(%)：")]
                        ),
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "比例之和为100%",
                            size: "mini",
                            maxlength: "5",
                            disabled:
                              this.ruleForm.payMode.indexOf("YHZZ") !== -1
                                ? false
                                : true,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.checkBlur()
                            },
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.valueChange($event, "yhzzPercentage")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.arbAdditionInfo.yhzzPercentage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.arbAdditionInfo,
                                "yhzzPercentage",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.arbAdditionInfo.yhzzPercentage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        class: {
                          "form-item": "true" == "true",
                          yhzzStyle:
                            this.ruleForm.payMode.indexOf("YHHP") == -1,
                        },
                        attrs: { prop: "arbAdditionInfo.yhhpPercentage" },
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                this.ruleForm.payMode.indexOf("YHHP") !== -1
                                  ? "#000"
                                  : "#c0c4cc",
                            },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v("银行汇票比例(%)：")]
                        ),
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "比例之和为100%",
                            size: "mini",
                            maxlength: "5",
                            disabled:
                              this.ruleForm.payMode.indexOf("YHHP") !== -1
                                ? false
                                : true,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.checkBlur()
                            },
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.valueChange($event, "yhhpPercentage")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.arbAdditionInfo.yhhpPercentage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.arbAdditionInfo,
                                "yhhpPercentage",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.arbAdditionInfo.yhhpPercentage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        class: {
                          "form-item": "true" == "true",
                          cdhpStyle:
                            this.ruleForm.payMode.indexOf("CDHP") == -1,
                        },
                        attrs: { prop: "arbAdditionInfo.cdhpPercentage" },
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                this.ruleForm.payMode.indexOf("CDHP") !== -1
                                  ? "#000"
                                  : "#c0c4cc",
                            },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v("商业汇票比例(%)：")]
                        ),
                        _c("el-input", {
                          staticClass: "input-width",
                          attrs: {
                            placeholder: "比例之和为100%",
                            size: "mini",
                            maxlength: "5",
                            disabled:
                              this.ruleForm.payMode.indexOf("CDHP") !== -1
                                ? false
                                : true,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.checkBlur()
                            },
                          },
                          nativeOn: {
                            input: function ($event) {
                              return _vm.valueChange($event, "cdhpPercentage")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.arbAdditionInfo.cdhpPercentage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.arbAdditionInfo,
                                "cdhpPercentage",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.arbAdditionInfo.cdhpPercentage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        class: {
                          "form-item": "true" == "true",
                          cdhpStyle:
                            this.ruleForm.payMode.indexOf("CDHP") == -1,
                        },
                        attrs: { prop: "bankBillTerm" },
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                this.ruleForm.payMode.indexOf("CDHP") !== -1
                                  ? "#000"
                                  : "#c0c4cc",
                            },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v("承兑汇票期限：")]
                        ),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              size: "small",
                              disabled:
                                this.ruleForm.payMode.indexOf("CDHP") !== -1
                                  ? false
                                  : true,
                            },
                            model: {
                              value: _vm.ruleForm.bankBillTerm,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "bankBillTerm", $$v)
                              },
                              expression: "ruleForm.bankBillTerm",
                            },
                          },
                          _vm._l(_vm.paymentmethod.time, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "付款安排：",
                          prop: "arbAdditionInfo.paySet",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.paySetMethod(
                                  _vm.ruleForm.arbAdditionInfo.paySet
                                )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.arbAdditionInfo.paySet,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.arbAdditionInfo,
                                  "paySet",
                                  $$v
                                )
                              },
                              expression: "ruleForm.arbAdditionInfo.paySet",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "QK" } }, [
                              _vm._v("一次性付款"),
                            ]),
                            _c("el-radio", { attrs: { label: "FQ" } }, [
                              _vm._v("分期付款"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.paySet == "FQ"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "分期付款：",
                              prop: "arbAdditionInfo.fqTermIndex",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input-width",
                              attrs: {
                                placeholder: "1-24期",
                                size: "mini",
                                onkeyup: "value=value.replace(/[^\\d]/g,'')",
                                maxlength: "2",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inputChange(
                                    _vm.ruleForm.arbAdditionInfo.fqTermIndex
                                  )
                                },
                              },
                              model: {
                                value: _vm.ruleForm.arbAdditionInfo.fqTermIndex,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.arbAdditionInfo,
                                    "fqTermIndex",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.arbAdditionInfo.fqTermIndex",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.paySet == "FQ" &&
                    _vm.paySetList
                      ? _c(
                          "el-table",
                          {
                            staticStyle: { width: "92%" },
                            attrs: {
                              data: _vm.ruleForm.payDeliverRateFkList,
                              "highlight-current-row": "",
                              border: "",
                              align: "center",
                              "row-style": { height: 0 + "px" },
                              "cell-style": { padding: 0 + "px" },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "termIndex", label: "期数" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " 第" +
                                            _vm._s(scope.row.termIndex) +
                                            "期 "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1929236414
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "rate", label: "金额比例" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "payDeliverRateFkList." +
                                                scope.$index +
                                                ".rate",
                                              rules: _vm.rules.FQrate,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "input-width-table",
                                              attrs: {
                                                size: "mini",
                                                oninput:
                                                  "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3').replace(/^\\./g, '')",
                                              },
                                              model: {
                                                value: scope.row.rate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "rate",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.rate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                738333165
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "date", label: "支付时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "payDeliverRateFkList." +
                                                scope.$index +
                                                ".date",
                                              rules: _vm.rules.FQdate,
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "input-width-table",
                                              attrs: {
                                                size: "mini",
                                                type: "date",
                                                "value-format": "yyyy-MM-dd",
                                                "picker-options": _vm.option,
                                                placeholder: "请选择开始时间",
                                              },
                                              model: {
                                                value: scope.row.date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "date",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.date",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4014731770
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "payMode", label: "付款方式" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "payDeliverRateFkList." +
                                                scope.$index +
                                                ".payMode",
                                              rules: _vm.rules.paymodeList,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "capital",
                                                model: {
                                                  value: scope.row.payMode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "payMode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.payMode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.paymentmethod.paymodeList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3226500908
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.paySet == "QK"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "付款要求/发货要求：",
                              prop: "arbAdditionInfo.paySetPort",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", size: "small" },
                                model: {
                                  value:
                                    _vm.ruleForm.arbAdditionInfo.paySetPort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.arbAdditionInfo,
                                      "paySetPort",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.arbAdditionInfo.paySetPort",
                                },
                              },
                              _vm._l(
                                _vm.paymentmethod.arrangement,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.paySet == "QK" &&
                    _vm.ruleForm.arbAdditionInfo.paySetPort == "QD"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-invsStatus",
                            attrs: { prop: "arbAdditionInfo.paySetDay" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "font-size": "14px" } },
                              [
                                _vm._v(" 签订合同之日起【"),
                                _c("el-input", {
                                  staticClass: "payinput-width",
                                  attrs: { size: "mini" },
                                  model: {
                                    value:
                                      _vm.ruleForm.arbAdditionInfo.paySetDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm.arbAdditionInfo,
                                        "paySetDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.arbAdditionInfo.paySetDay",
                                  },
                                }),
                                _vm._v("】个工作日内 "),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 11 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.paySet == "QK" &&
                    _vm.ruleForm.arbAdditionInfo.paySetPort == "YS"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item-invsStatus",
                            attrs: { prop: "arbAdditionInfo.paySetDay" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "font-size": "14px" } },
                              [
                                _vm._v(" 收到全部产品且验收合格之日起【"),
                                _c("el-input", {
                                  staticClass: "payinput-width",
                                  attrs: { size: "mini" },
                                  model: {
                                    value:
                                      _vm.ruleForm.arbAdditionInfo.paySetDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm.arbAdditionInfo,
                                        "paySetDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.arbAdditionInfo.paySetDay",
                                  },
                                }),
                                _vm._v("】个工作日内 "),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btnBoxStyle" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnLast",
                    attrs: { type: "primary" },
                    on: { click: _vm.last },
                  },
                  [_vm._v("上一步")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnNext",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.next("ruleForm")
                      },
                    },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showState
          ? _c(
              "div",
              { staticClass: "submit-state" },
              [
                _vm._m(0),
                _c("p", { staticClass: "state-msg" }, [_vm._v("提交成功")]),
                _c("p", { staticClass: "state-desc" }, [
                  _vm._v(" 您的竞卖信息已提交成功，等待平台审核，请及时查询 "),
                ]),
                _c(
                  "router-link",
                  { attrs: { to: "./MyBidding" } },
                  [
                    _c("el-button", { attrs: { plain: "" } }, [
                      _vm._v("查看我的竞卖"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "state-icon" }, [
      _c("i", { staticClass: "el-icon-success" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }