var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "150px",
            },
          },
          [
            _c("div", { staticClass: "basicBox" }, [
              _c("div", { staticClass: "titleFont" }, [_vm._v("基本信息")]),
              _c(
                "div",
                { staticClass: "basic" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品图片：", prop: "picUrl" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "chen-image-uploader",
                                  attrs: {
                                    action: _vm.uploadPath,
                                    "show-file-list": false,
                                    "on-success": _vm.handleSuccess,
                                    "before-upload": _vm.beforeUpload,
                                  },
                                },
                                [
                                  _vm.ruleForm.picUrl
                                    ? _c("img", {
                                        staticClass: "upload-image",
                                        attrs: { src: _vm.ruleForm.picUrl },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus image-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "订单模式：",
                                prop: "orderProcess",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-width",
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "mini",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeOrderProcess(
                                        _vm.ruleForm.orderProcess
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.orderProcess,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "orderProcess",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.orderProcess",
                                  },
                                },
                                _vm._l(
                                  _vm.paymentmethod.orderProcess,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品名称：", prop: "prodName" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  placeholder: "请输入产品名称",
                                  size: "mini",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.ruleForm.prodName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "prodName", $$v)
                                  },
                                  expression: "ruleForm.prodName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "产品类型：",
                                prop: "dtsCategoryId",
                              },
                            },
                            [
                              _c("el-cascader", {
                                staticClass: "input-width",
                                attrs: {
                                  options: _vm.categoryList,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.ruleForm.dtsCategoryId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "dtsCategoryId", $$v)
                                  },
                                  expression: "ruleForm.dtsCategoryId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "规格型号：",
                                prop: "prodModels",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  placeholder: "请输入规格型号",
                                  size: "mini",
                                  maxlength: "20",
                                },
                                model: {
                                  value: _vm.ruleForm.prodModels,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "prodModels", $$v)
                                  },
                                  expression: "ruleForm.prodModels",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "数量：", prop: "amount" } },
                            [
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c("el-input", {
                                        staticClass: "input-width",
                                        attrs: {
                                          maxlength: "10",
                                          placeholder: "请输入数量",
                                          size: "mini",
                                        },
                                        nativeOn: {
                                          input: function ($event) {
                                            return _vm.valueAmount(
                                              $event,
                                              "amount"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.ruleForm.amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.amount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 7 } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "input-width",
                                          attrs: {
                                            placeholder: "单位",
                                            "allow-create": "",
                                            filterable: "",
                                            "default-first-option": "",
                                            size: "mini",
                                          },
                                          model: {
                                            value: _vm.ruleForm.units,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "units",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.units",
                                          },
                                        },
                                        _vm._l(
                                          _vm.unitList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "合同模板：",
                                prop: "contractinfono",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择合同模板",
                                    "popper-append-to-body": false,
                                  },
                                  model: {
                                    value: _vm.ruleForm.contractinfono,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "contractinfono",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.contractinfono",
                                  },
                                },
                                _vm._l(_vm.contractList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.tContractTextNo,
                                      attrs: {
                                        id: "contract-id",
                                        label: item.tContractTextName,
                                        value: item.tContractTextNo,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "contract-box" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "contract-left" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.tContractTextName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "num" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.tContractTextNo)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "contract-rigth",
                                              staticStyle: { color: "#8492a6" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showContractDialog(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 查看 ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: "合同模板",
                                visible: _vm.contractDialogVisible,
                                width: "700px",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.contractDialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.contractTemplate),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同编号：",
                                prop: "contractNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  maxlength: "50",
                                  placeholder: "请输入合同编号",
                                  size: "mini",
                                  onkeyup:
                                    "this.value=this.value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.valueNumber(
                                      $event,
                                      "contractNumber"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.contractNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "contractNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.contractNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "basicBox" }, [
              _c("div", { staticClass: "titleFont" }, [_vm._v("起拍信息")]),
              _c(
                "div",
                { staticClass: "basic" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "开始时间：", prop: "startTime" },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "input-width",
                                attrs: {
                                  size: "mini",
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "picker-options": _vm.option,
                                  placeholder: "请选择开始时间",
                                },
                                model: {
                                  value: _vm.ruleForm.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "startTime", $$v)
                                  },
                                  expression: "ruleForm.startTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "起拍价(元)：", prop: "upPrice" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  maxlength: 13,
                                  autocomplete: "off",
                                  placeholder: "请输入起拍价",
                                  size: "mini",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.blurmethod($event, "upPrice")
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.keyup($event, "upPrice")
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.upPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "upPrice", $$v)
                                  },
                                  expression: "ruleForm.upPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最低价(元)：",
                                prop: "bottomPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  maxlength: 13,
                                  autocomplete: "off",
                                  placeholder: "请输入最低价",
                                  size: "mini",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.blurmethod($event, "bottomPrice")
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.keyup($event, "bottomPrice")
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.bottomPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "bottomPrice", $$v)
                                  },
                                  expression: "ruleForm.bottomPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "持续时间(分钟)：",
                                prop: "continueTime",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  placeholder: "请输入持续时间",
                                  size: "mini",
                                  maxlength: 5,
                                },
                                model: {
                                  value: _vm.ruleForm.continueTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "continueTime", $$v)
                                  },
                                  expression: "ruleForm.continueTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "降价策略(元/分钟)：",
                                prop: "failTactics",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  maxlength: 13,
                                  autocomplete: "off",
                                  placeholder: "请输入降价策略",
                                  size: "mini",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.blurmethod($event, "failTactics")
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.keyup($event, "failTactics")
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.failTactics,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "failTactics", $$v)
                                  },
                                  expression: "ruleForm.failTactics",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "basicBox" }, [
              _c("div", { staticClass: "titleFont" }, [_vm._v("发票信息")]),
              _c(
                "div",
                { staticClass: "basic" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "发票类型：",
                                prop: "invoiceType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.invoiceType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "invoiceType", $$v)
                                    },
                                    expression: "ruleForm.invoiceType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("专用发票"),
                                  ]),
                                  _c(
                                    "el-radio",
                                    { attrs: { disabled: "", label: "1" } },
                                    [_vm._v("普通发票")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "适用税率(%)：",
                                prop: "arbAdditionInfo.taxRate",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  placeholder: "请输入税率",
                                  size: "mini",
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.valueChange($event, "taxRate")
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.arbAdditionInfo.taxRate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.arbAdditionInfo,
                                      "taxRate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.arbAdditionInfo.taxRate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "开票时间：",
                                prop: "arbAdditionInfo.invsStatus",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.ruleForm.arbAdditionInfo.invsStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm.arbAdditionInfo,
                                        "invsStatus",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.arbAdditionInfo.invsStatus",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "KQ" } }, [
                                    _vm._v("付款前开发票"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "KH" } }, [
                                    _vm._v("付款后开发票"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item-invsStatus",
                              attrs: { prop: "arbAdditionInfo.invsDay" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  style: {
                                    color:
                                      _vm.ruleForm.arbAdditionInfo.invsStatus ==
                                      "KH"
                                        ? "#000"
                                        : "#c0c4cc",
                                  },
                                },
                                [
                                  _vm._v(" 付款之日起【"),
                                  _c("el-input", {
                                    staticClass: "payinput-width",
                                    attrs: {
                                      disabled:
                                        this.ruleForm.arbAdditionInfo
                                          .invsStatus == "KH"
                                          ? false
                                          : true,
                                      size: "mini",
                                    },
                                    model: {
                                      value:
                                        _vm.ruleForm.arbAdditionInfo.invsDay,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm.arbAdditionInfo,
                                          "invsDay",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.arbAdditionInfo.invsDay",
                                    },
                                  }),
                                  _vm._v("】个工作日内开票 "),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("el-form-item", [
              _c(
                "div",
                { staticClass: "btnBoxStyle" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnstyle",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.next("ruleForm")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }