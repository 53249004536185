<template>
  <div class="wrap">
    <div class="content">
          <!-- 遮罩 -->
      <div class="transparentBox" v-if="examineShow"></div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <!-- {{ ruleForm }}///{{ bidId }} -->
        <el-form-item class="BidReqRemark" label="竞卖说明：" prop="reqRemark">
          <tinymce-editor
            :value="ruleForm.autionDetails"
            @input="ruleForm.autionDetails = $event"
            style="width: 90%"
          ></tinymce-editor>
        </el-form-item>
        <!--<el-form-item label="竞卖说明：" prop="autionDetails">
          &lt;!&ndash; <el-input class="input-width" v-model="ruleForm.autionDetails" :rows="6" placeholder="根据提示内容填写相关备注：1、交货期； 2、交货方式、地点； 3、结算方式：发票、数期等； 4、货物外观质量备注。" type="textarea" style="width: 90%"></el-input> &ndash;&gt;
          <tinymce-editor :value="ruleForm.autionDetails" @input="ruleForm.autionDetails = $event" style="width: 90%"></tinymce-editor>
        </el-form-item>-->
        <el-form-item class="btnBoxStyle">
          <el-button class="btnLast" type="primary" @click="last">上一步</el-button>
          <el-button class="btnNext" type="primary" @click="next('ruleForm')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// import { categoryList } from "../../../Home/api";
import {
  addsAutionInfo,
  uploadPath,
  autionInfoById,
  myAutionInfoUpdate,
  categoryAll,
  getAUContract,
  getContractInfo,
} from "../../../../api";
// import publicMethod from "../../../../assets/js/public";
import tinymceEditor from "@/components/tinymceEditor.vue";

export default {
  components: {
    "tinymce-editor": tinymceEditor,
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bidId: {
      type: String,
      default: () => {
        return {};
      },
    },
        examineShow: {
      type: Boolean,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    bidId: {
      handler(val) {
        this.id = val;
        console.log("idd", this.id);
      },
      immediate: true,
    },

    "ruleForm.arbAdditionInfo.fqTermIndex": function (newVal, oldVal) {
      console.log(newVal + "------" + oldVal);
    },

    "ruleForm.orderProcess": function (newVal, oldVal) {
      console.log(newVal + "--3333" + oldVal);
      if (newVal == "HK" && oldVal == "KH") {
        this.ruleForm.arbAdditionInfo.invsStatus = "KQ";
      }
      if (newVal == "KH" && oldVal == "HK") {
        this.ruleForm.arbAdditionInfo.invsStatus = "KH";
      }
    },

    "ruleForm.arbAdditionInfo.paySet": function (newVal, oldVal) {
      console.log(newVal + "--paySet" + oldVal);
      if (newVal == "FQ") {
        this.ruleForm.arbAdditionInfo.paySetPort = "";
      }

      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.ruleForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.paySetList = true;
      }
    },

    "ruleForm.arbAdditionInfo.deliverSet": function (newVal, oldVal) {
      console.log(newVal + "--deliverSet" + oldVal);

      if (newVal == "FQ") {
        this.ruleForm.arbAdditionInfo.deliverSetPort = "";
      }

      if (
        newVal == "FQ" &&
        oldVal == "QK" &&
        this.ruleForm.arbAdditionInfo.fqTermIndex !== ""
      ) {
        this.deliverSetList = true;
      }
    },

    "ruleForm.arbAdditionInfo.deliverMode": function (newVal, oldVal) {
      console.log(newVal + "--222222" + oldVal);
      if (newVal == "SH" && oldVal == "ZT") {
        this.ruleForm.arbAdditionInfo.deliverModeDay = "";
      }
      //    if (newVal == "ZT"&&oldVal=='SH') {
      //   this.ruleForm.arbAdditionInfo.deliverModeDay = "";
      // }
    },
    "ruleForm.arbAdditionInfo.qualityMode": function (newVal, oldVal) {
      if (newVal == "ORTHER" && oldVal == "GZB") {
        this.ruleForm.arbAdditionInfo.qualityTerm = "";
      }

      // if (newVal == "GZB"&&oldVal=='ORTHER') {

      //   this.ruleForm.arbAdditionInfo.qualityTerm = "";
      // }
    },
    "ruleForm.arbAdditionInfo.invsStatus": function (newVal, oldVal) {
      if (newVal == "KQ" && oldVal == "KH") {
        this.ruleForm.arbAdditionInfo.invsDay = "";
      }
    },
  },
  data() {
    var validateAmount = (rule, value, callback) => {
      if (!value && this.ruleForm.units == "") {
        return callback(new Error("请输入数量和单位"));
      } else if (!value) {
        return callback(new Error("请输入数量"));
      } else if (
        value.toString().indexOf(".") > 0 &&
        value.toString().split(".")[1].length > 6
      ) {
        return callback(new Error("最多保留六位小数"));
      } else {
        callback();
      }
    };
    var validateQualityTerm = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 180) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else if (value.indexOf(".") !== -1) {
        return callback(new Error("请输入 1 ~ 180之间的整数质保期限"));
      } else if (value.length !== 1 && value[0] == 0) {
        return callback(new Error("请输入 1 ~ 180之间的质保期限"));
      } else {
        callback();
      }
    };
    var validateUpPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入起拍价"));
      } else if (Number(value) >= 10000000000) {
        return callback(new Error("起拍价不大于 10000000000 元"));
      } else if (
        this.ruleForm.bottomPrice != "" &&
        Number(value) < Number(this.ruleForm.bottomPrice)
      ) {
        return callback(new Error("起拍价不得低于最低价"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else {
        callback();
      }
    };
    var validateBottomPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入最低价"));
      } else if (Number(value) >= 10000000000) {
        return callback(new Error("最低价不大于 10000000000 元"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else {
        if (this.ruleForm.upPrice != "") {
          this.$refs.ruleForm.validateField("upPrice");
        }
        callback();
      }
    };
    var validateFailTactics = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入降价策略"));
      } else if (Number(value) >= 10000000000) {
        return callback(new Error("降价策略不大于 10000000000 元"));
      } else if (
        value == "0" ||
        value == "00" ||
        value == "0.00" ||
        value == "0.0"
      ) {
        return callback(new Error("输入值不得等于0"));
      } else {
        callback();
      }
    };
    var validateStartTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else if (new Date(value).getTime() < Date.now() - 1000) {
        return callback(new Error("开始时间不能早于当前时间"));
      } else {
        callback();
      }
    };
    var validateStartTimeTable = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };

    var validateTaxRate = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value >= 100) {
        return callback(new Error("请输入 0 ~ 99.99 之间税率"));
      } else {
        callback();
      }
    };
    var validatePayAfterData = (rule, value, callback) => {
      if (!value || isNaN(value) || value <= 0 || value > 30) {
        return callback(new Error("请输入 1 ~ 30之间日期"));
      } else {
        callback();
      }
    };

    var validateYhzzPercentage = (rule, value, callback) => {
      if (this.ruleForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }

      if (
        this.ruleForm.payMode.length == 1 &&
        this.ruleForm.arbAdditionInfo.yhzzPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }
      // console.log(this.ruleForm.payMode.indexOf("CDHP"));
      if (
        this.ruleForm.payMode.indexOf("YHZZ") !== -1 &&
        this.ruleForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.ruleForm.arbAdditionInfo.cdhpPercentage =
          100 - parseFloat(this.ruleForm.arbAdditionInfo.yhzzPercentage);
        return callback();
      }

      this.ruleForm.arbAdditionInfo.cdhpPercentage = "";
      return callback();
    };

    var validateCdhpPercentage = (rule, value, callback) => {
      if (this.ruleForm.payMode.length == 2) {
        if (!value || isNaN(value) || value < 0 || value > 100) {
          return callback(new Error("百分之1-99 比例相加需等于100"));
        }
      }
      if (
        this.ruleForm.payMode.length == 1 &&
        this.ruleForm.arbAdditionInfo.cdhpPercentage !== "100"
      ) {
        return callback(new Error("单选时比例需为100"));
      }

      if (
        this.ruleForm.payMode.indexOf("YHZZ") !== -1 &&
        this.ruleForm.payMode.indexOf("CDHP") !== -1
      ) {
        this.ruleForm.arbAdditionInfo.yhzzPercentage =
          100 - parseFloat(this.ruleForm.arbAdditionInfo.cdhpPercentage);
        return callback();
      }
      this.ruleForm.arbAdditionInfo.yhzzPercentage = "";
      return callback();
    };

    var validatePayMode = (rule, value, callback) => {
      if (
        this.ruleForm.payMode.indexOf("YHZZ") == -1 &&
        this.ruleForm.payMode.indexOf("CDHP") == -1
      ) {
        return callback(new Error("请选择付款方式"));
      }
      return callback();
    };

    var validateFQrate = (rule, value, callback) => {
      let sum = 0;
      this.ruleForm.payDeliverRateFkList.map((item) => {
        // console.log(item.rate);
        sum = sum + parseFloat(item.rate);
      });
      // console.log("sum", sum);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateJHrate = (rule, value, callback) => {
      let sum = 0;
      this.ruleForm.payDeliverRateJhList.map((item) => {
        sum = sum + parseFloat(item.rate);
      });
      // console.log(value);
      if (value == 0 || value == "0") {
        return callback(new Error("金额比例不得为0"));
      }
      if (!value) {
        return callback(new Error("请输入金额比例"));
      }
      if (sum > 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum < 100) {
        return callback(new Error("输入比例相加需等于100"));
      }
      if (sum == 0) {
        return callback(new Error("请输入金额比例/金额比例不得为0"));
      }
      if (sum == 100) {
        return callback();
      }
      return callback();
    };
    var validateFQtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }

      let newList = [];

      this.ruleForm.payDeliverRateFkList.map((item) => {
        newList.push(item.termIndex);
      });
      console.log("   this.newListFk", newList);

      for (var i = 0; i < newList.length; i++) {
        console.log("newList[i]", newList[i]);
        if (newList.indexOf(newList[i]) != -1) {
          console.log("找到了", newList.indexOf(newList[i]));
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };
    var validateJHtermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择定金/质保金/期数"));
      }

      // let newList = [];

      this.ruleForm.payDeliverRateJhList.map((item) => {
        this.newListJh.push(item.termIndex);
      });

      for (var i = 0; i < this.newListJh.length; i++) {
        if (this.newListJh.indexOf(this.newListJh[i]) != i) {
          return callback(new Error("不可重复选择"));
        }
      }

      return callback();
    };

    var validatefqTermIndex = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入分期期数"));
      }
      if (/^(?:[1-9]\d*)$/.test(value) == false) {
        callback(new Error("请输入正整数"));
      }
      if (parseFloat(value) < 0 || parseFloat(value) > 24) {
        this.deliverSetList = false;
        this.paySetList = false;
        return callback(new Error("请输入期数需为1-24"));
      }
      this.deliverSetList = true;
      this.paySetList = true;
      return callback();
    };

    return {
      deliverSetList: false,
      paySetList: false,
      FkArrange: "",
      newListFk: [],
      newListJh: [],
      contractTemplate: "",
      contractDialogVisible: false,
      contractList: [],
      contractInfo: [],
      uploadPath,
      pageStete: "publish",
      categoryList: [],
      order: 1,
      unitList: ["吨", "公斤", "克", "件", "套", "根", "个", "平方米"],
      // ruleForm: {
      //   id: "",
      //   picUrl: "", //产品图片
      //   prodName: "", //产品名称
      //   dtsCategoryId: "", //产品类型
      //   prodModels: "", //规格型号
      //   amount: "", //数量
      //   units: "", //单位
      //   upPrice: "", //起拍价
      //   bottomPrice: "", //最低价
      //   failTactics: "", //降价策略
      //   startTime: "", //开始时间
      //   continueTime: "", //持续时间
      //   autionDetails: "", //竞卖说明
      //   contractinfono: "",
      //   orderProcess: "", //订单模式
      //   payMode: [], //付款方式
      //   bankBillTerm: "", //承兑汇票期限
      //   testlist: [],
      //   //付款分期表格
      //   payDeliverRateFkList: [
      //     // {
      //     //   termIndex: "",
      //     //   rate: "",
      //     //   date: "", //分期付款开始时间
      //     //   payMode: "",
      //     //   type: "FK",
      //     // },
      //   ],
      //   //交货分期表格
      //   payDeliverRateJhList: [
      //     // {
      //     //   termIndex: "",
      //     //   rate: "",
      //     //   date: "", //交货付款开始时间
      //     //   // payMode: "",
      //     //   type: "JH",
      //     // },
      //   ],

      //   arbAdditionInfo: {
      //     fqTermIndex: "",
      //     type: "AU", //竞卖
      //     taxRate: "", // 使用税率
      //     invsStatus: "", // 开票时间
      //     paySet: "", //付款安排
      //     deliverSet: "", //交付安排
      //     deliverMode: "", // 交货方式
      //     packageMode: "", //包装方式
      //     checkMode: "", //验收条件
      //     qualityMode: "", //质保方式
      //     qualityTerm: "", //质保的日期
      //     paySetPort: "", // 付款安排数据
      //     deliverSetPort: "", // 交付安排数据

      //     invsDay: "", //付款后开发票日期
      //     deliverModeDay: "", //买家自提的日期
      //     bankBillTerm: "", //承兑汇票期限
      //     payMode: "",
      //     //------------------------
      //     paySetDay: "", //付款安排一次性付款日期

      //     //---------------------------
      //     deliverSetDay: "", //交货安排一次性交货日期

      //     //-----------------------------
      //     payPercent: "", // 付款占比
      //     yhzzPercentage: "", //银行转账百分比
      //     cdhpPercentage: "", //承兑汇票百分比
      //   },
      // },
      publishForm: {
        productName: "",
        categoryId: "",
        productSpec: "",
        maxPrice: "",
        reqNum: "",
        unit: "",
        picUrl: "",
        startTime: "",
        validDate: "",
        dateTime: "",
        date: "",
        time: "",
        reqRemark: "",
        addressId: "",
        deliverDay: "",
        stepPrice: "",
        // forceShow: '',
        contractInfo: "",
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        taxRate: "", // 使用税率
        invsStatus: "", // 票据时间
        paySet: "", //付款安排
        paySetPort: "", // 付款安排数据
        invsDay: "", //付款后开发票日期
      },
      rules: {
        picUrl: [
          {
            required: true,
            message: "请上传产品图片",
            trigger: ["blur", "change"],
          },
        ],
        prodName: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        dtsCategoryId: [
          { required: true, message: "请选择产品类型", trigger: "change" },
        ],
        prodModels: [
          { required: true, message: "请输入规格型号", trigger: "blur" },
        ],
        amount: [
          { required: true, validator: validateAmount, trigger: "blur" },
        ],
        upPrice: [
          { required: true, validator: validateUpPrice, trigger: "blur" },
        ],
        bottomPrice: [
          { required: true, validator: validateBottomPrice, trigger: "blur" },
        ],
        failTactics: [
          { required: true, validator: validateFailTactics, trigger: "blur" },
        ],
        startTime: [
          {
            required: true,
            validator: validateStartTime,
            trigger: ["blur", "change"],
          },
        ],
        continueTime: [
          { required: true, message: "请输入持续时间", trigger: "blur" },
        ],
        contractinfono: [
          {
            required: true,
            message: "请选择合同模板",
            trigger: ["blur", "change"],
          },
        ],
        orderProcess: [
          { required: true, message: "请选择订单模式", trigger: "change" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        payMode: [
          {
            required: true,
            // message: "请选择付款方式",
            validator: validatePayMode,
            trigger: ["blur", "change"],
          },
        ],
        bankBillTerm: [
          { required: true, message: "请选择汇票期限", trigger: "change" },
        ],
        "arbAdditionInfo.taxRate": [
          {
            required: true,
            validator: validateTaxRate,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.invsStatus": [
          { required: true, message: "请选择开票时间", trigger: "change" },
        ],
        "arbAdditionInfo.paySet": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSet": [
          { required: true, message: "请选择交货安排", trigger: "change" },
        ],

        "arbAdditionInfo.invsDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.paySetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        // "arbAdditionInfo.acceptanceQualifiedDate": [
        //   {
        //     required: true,
        //     validator: validatePayAfterData,
        //     trigger: ["blur", "change"],
        //   },
        // ],
        "arbAdditionInfo.deliverSetDay": [
          {
            required: true,
            validator: validatePayAfterData,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.paySetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        "arbAdditionInfo.deliverSetPort": [
          { required: true, message: "请选择付款安排", trigger: "change" },
        ],
        //百分比

        "arbAdditionInfo.yhzzPercentage": [
          {
            required: true,
            validator: validateYhzzPercentage,
            trigger: ["blur", "change"],
          },
        ],
        "arbAdditionInfo.cdhpPercentage": [
          {
            required: true,
            validator: validateCdhpPercentage,
            trigger: ["blur", "change"],
          },
        ],

        "arbAdditionInfo.deliverMode": [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.deliverModeDay": [
          {
            required: true,
            // message: "请选择提货日期",
            validator: validatePayAfterData,
            trigger: "change",
          },
        ],

        "arbAdditionInfo.packageMode": [
          {
            required: true,
            message: "请选择包装方式",
            trigger: "change",
          },
        ],

        "arbAdditionInfo.checkMode": [
          {
            required: true,
            message: "请选择验收条件",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityMode": [
          {
            required: true,
            message: "请选择质保方式",
            trigger: "change",
          },
        ],
        "arbAdditionInfo.qualityTerm": [
          {
            required: true,

            validator: validateQualityTerm,
            trigger: "change",
          },
        ],
        "arbAdditionInfo.fqTermIndex": [
          {
            required: true,
            validator: validatefqTermIndex,
            trigger: ["blur", "change"],
          },
        ],

        FQdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        FQrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateFQrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        FQtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateFQtermIndex,

            trigger: ["blur", "change"],
          },
        ],
        paymodeList: [
          {
            required: true,
            message: "请选择方式",

            trigger: ["blur", "change"],
          },
        ],
        JHdate: [
          {
            required: true,
            validator: validateStartTimeTable,
            trigger: ["blur", "change"],
          },
        ],

        JHrate: [
          {
            required: true,
            // message: "请填写金额比例",
            validator: validateJHrate,
            trigger: ["blur", "change"],
            // trigger: "blur",
          },
        ],
        JHtermIndex: [
          {
            required: true,
            // message: "请选择定金/质保金/期数",
            validator: validateJHtermIndex,
            trigger: ["change"],
          },
        ],
      },
      productDetail: null,
      paymentmethod: {
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        payListOption: [
          {
            value: "1",
            label: "第1期",
          },
          {
            value: "2",
            label: "第2期",
          },
          {
            value: "3",
            label: "第3期",
          },
          {
            value: "4",
            label: "第4期",
          },
          {
            value: "5",
            label: "第5期",
          },
          {
            value: "6",
            label: "第6期",
          },
          {
            value: "7",
            label: "第7期",
          },
          {
            value: "8",
            label: "第8期",
          },
          {
            value: "9",
            label: "第9期",
          },
          {
            value: "10",
            label: "第10期",
          },
          {
            value: "11",
            label: "第11期",
          },
          {
            value: "12",
            label: "第12期",
          },
          {
            value: "13",
            label: "第13期",
          },
          {
            value: "14",
            label: "第14期",
          },
          {
            value: "15",
            label: "第15期",
          },
          {
            value: "16",
            label: "第16期",
          },
          {
            value: "17",
            label: "第17期",
          },
          {
            value: "18",
            label: "第18期",
          },
          {
            value: "19",
            label: "第19期",
          },
          {
            value: "20",
            label: "第20期",
          },
          {
            value: "21",
            label: "第21期",
          },
          {
            value: "22",
            label: "第22期",
          },
          {
            value: "23",
            label: "第23期",
          },
          {
            value: "24",
            label: "第24期",
          },
        ],
        time: [
          {
            value: 1,
            label: "1个月",
          },
          {
            value: 3,
            label: "3个月",
          },
          {
            value: 6,
            label: "6个月",
          },
          {
            value: 9,
            label: "9个月",
          },
          {
            value: 12,
            label: "12个月",
          },
        ],
        arrangement: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "YS",
            label: "验收合格之后",
          },
        ],

        arrangementDelivery: [
          {
            value: "QD",
            label: "合同签订之后",
          },
          {
            value: "SK",
            label: "全部收款之后",
          },
        ],
        billingTimeList: [
          {
            value: "KH",
            label: "付款后开发票",
          },
          {
            value: "KQ",
            label: "付款前开发票",
          },
        ],
        BuyerSelfDeliveryDate: [
          {
            value: 7,
            label: "7天",
          },
          {
            value: 14,
            label: "14天",
          },
        ],
        paymodeList: [
          {
            value: "YHZZ",
            label: "银行转账",
          },
          {
            value: "CDHP",
            label: "承兑汇票",
          },
        ],
      },
      typeList: [
        {
          id: "铝原料",
          name: "铝原料",
        },
      ],

      option: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        },
      },
    };
  },
  created() {
    this.$store.commit("changeMenuType", "SELL");
    this.getAllCategory();
    this.pageStete = this.$route.query.id ? "edit" : "publish";
    this.getAUContract();
  },
  methods: {
    keyup(e, val) {
      //限制10位整数 与两位小数的正则规则
      if (e.target.value[0] === ".") {
        e.target.value = e.target.value.replace(/^\./g, "");
        this.ruleForm[val] = e.target.value;
      } else if (e.target.value[0] === "0" && /\d/.test(e.target.value[1])) {
        e.target.value = e.target.value.substring(1);
      } else if (e.target.value.includes(".")) {
        const arr = e.target.value.split(".");
        if (arr[0].length > 10) {
          e.target.value = `${arr[0].substring(0, 12)}.${arr[1]}`;
          this.ruleForm[val] = e.target.value;
        } else {
          e.target.value = e.target.value.replace(/[^(\d\.)]/g, "");
          e.target.value = e.target.value.replace(
            /^(\d{1,12}\.?\d{0,2}).*/,
            "$1"
          );
          this.ruleForm[val] = e.target.value;
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "");
        e.target.value = e.target.value.replace(/^(\d{0,10}).*/, "$1");
        this.ruleForm[val] = e.target.value;
      }
    },
    blurmethod(e, val) {
      if (e.target.value[e.target.value.length - 1] === ".") {
        e.target.value = e.target.value.substring(0, [
          e.target.value.length - 1,
        ]);
        this.ruleForm[val] = e.target.value;
      }

      //  console.log( e.target.value.length==12 &&e.target.value.indexOf('.')==-1);
      //  console.log(e.target.value.indexOf('.')==-1);
    },

    deliverSetMethod(newVal) {
      if (newVal == "QK" && this.ruleForm.orderProcess == "KH") {
        this.ruleForm.arbAdditionInfo.deliverSetPort = "SK";
      }
    },
    paySetMethod(newVal) {
      console.log("paySetMethod", newVal);
      if (newVal == "QK" && this.ruleForm.orderProcess == "HK") {
        this.ruleForm.arbAdditionInfo.paySetPort = "YS";
      }
    },

    inputChange(newVal) {
      console.log("newVal", newVal);
      let newTermIndex = parseFloat(newVal);

      this.ruleForm.payDeliverRateFkList = [];
      this.ruleForm.payDeliverRateJhList = [];
      // this.deliverSetList = true;
      // this.paySetList = true;
      for (var i = 0; i < newTermIndex; i++) {
        let obj = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          payMode: "",

          type: "FK",
        };

        this.ruleForm.payDeliverRateFkList.push(obj);

        let obj1 = {
          termIndex: i + 1 + "",
          rate: "",
          date: "",
          // payMode: "",

          type: "JH",
        };
        // this.ruleForm.payDeliverRateJhList = [];

        this.ruleForm.payDeliverRateJhList.push(obj1);
      }
    },
    termIndexChange(val, value) {
      console.log(val, value);

      // for (let i = 0; i < this.paymentmethod.payListOption.length; i++) {
      //   if (value == this.paymentmethod.payListOption[i].value) {
      //     this.paymentmethod.payListOption.splice(i, 1);
      //     console.log(this.paymentmethod.payListOption.splice(i, 0));
      //     // break; // 有多个 id值 等于2时 不能break
      //   }
      // }

      this.ruleForm.payDeliverRateJhList[val].termIndex = value;
    },
    JHtermIndexChange(val, value) {
      console.log(val, value);
      this.ruleForm.payDeliverRateFkList[val].termIndex = value;
    },

    showContractDialog(content) {
      getContractInfo({
        id: content,
      }).then((res) => {
        if (res.errno === 0) {
          // this.contractInfo = res.data.list || [];
          this.contractTemplate = res.data.list[0].contractTextModel;
        }
      });

      this.contractDialogVisible = true;
    },
    paymentMethod(e) {
      if ((e = "YHZZ")) {
        this.publishForm.bankBillTerm = "";
      }
    },
    // 图片上传之前
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 图片上传成功
    handleSuccess(res, file) {
      if (res.errno == 0) {
        this.ruleForm.picUrl = res.data.url;
        this.$notify.success("上传成功");
        this.$refs.ruleForm.validateField("picUrl");
      }
    },
    last() {
      if (this.examineShow == true) {
        this.$emit("lastShow");
      } else {
      this.$emit("last", JSON.parse(JSON.stringify(this.ruleForm)), this.id);
      }
    },
    // 立即发布
    next(formName) {
      var _this = this;
        if (this.examineShow == true) {
        this.$emit("nextShow");
      } else {
      _this.$refs[formName].validate((valid) => {
        console.log("vaild", valid);
        if (valid) {
          this.$emit("next", JSON.parse(JSON.stringify(this.ruleForm)));
          this.ruleForm.payMode = this.ruleForm.payMode
            .toString()
            .replaceAll(",", "|");
          console.log(" this.ruleForm.payMode", this.ruleForm.payMode);
          this.ruleForm.arbAdditionInfo.payMode = this.ruleForm.payMode;

          let YHZZper = this.ruleForm.arbAdditionInfo.yhzzPercentage;
          let CDHPper = this.ruleForm.arbAdditionInfo.cdhpPercentage;
          let YHHPper = this.ruleForm.arbAdditionInfo.yhhpPercentage;

          if (this.ruleForm.payMode == [] || this.ruleForm.payMode == "") {
            this.ruleForm.arbAdditionInfo.payPercent = "";
          } else if (
            this.ruleForm.payMode.length == 14 ||
            this.ruleForm.payMode.length == 9
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            if (CDHPper == "") {
              CDHPper = "0";
            }
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + CDHPper + "|" + YHHPper;

            console.log("payPercent1");
          } else if (
            this.ruleForm.payMode.length == 4 &&
            this.ruleForm.payMode.indexOf("YHZZ") !== -1
          ) {
            if (YHZZper == "") {
              YHZZper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              YHZZper + "|" + "0" + "|" + "0";
          } else if (
            this.ruleForm.payMode.length == 4 &&
            this.ruleForm.payMode.indexOf("CDHP") !== -1
          ) {
            if (CDHPper == "") {
              CDHPper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              "0" + "|" + CDHPper + "|" + "0";
          } else if (
            this.ruleForm.payMode.length == 4 &&
            this.ruleForm.payMode.indexOf("YHHP") !== -1
          ) {
            if (YHHPper == "") {
              YHHPper = "0";
            }
            this.ruleForm.arbAdditionInfo.payPercent =
              "0" + "|" + "0" + "|" + YHHPper;
          }
          this.ruleForm.arbAdditionInfo.bankBillTerm =
            this.ruleForm.bankBillTerm;

          let axiosData = Object.assign({}, _this.ruleForm);
          axiosData.startTime = axiosData.startTime;
          axiosData.dtsCategoryId = axiosData.dtsCategoryId[1];
          let NowAxiosData = { ...axiosData };
          // debugger
          //修改
          myAutionInfoUpdate({
            ...NowAxiosData,
            id: this.id,
            steps: "1",
          }).then((res) => {
            if (res.errno == 0) {
              // this.$message({
              //   type: "success",
              //   message: "修改成功！",
              // });
              this.$emit("addNext");
            }
          });
        }
      });
      }
    },
    // termIndexchange(val) {
    //   console.log(val);
    //   this.ruleForm.payDeliverRateJhList.termIndex = val;
    //   for (let i = 0; i < this.ruleForm.payDeliverRateJhList.length; i++) {

    //   }
    // },

    getAllCategory() {
      categoryAll().then((res) => {
        this.categoryList = res.data.map((parent) => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map((child) => {
              return {
                value: child.id,
                label: child.name,
              };
            }),
          };
        });
      });
    },
    getAUContract() {
      getAUContract({
        // page: 1,
        // limit: 100
      }).then((res) => {
        if (res.errno === 0) {
          this.contractList = res.data.list || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .BidReqRemark {
  margin-left: 200px !important;
  margin-top: 60px;
}
/deep/ .next {
  margin-left: 480px !important;
}
/deep/ .el-table__row current-row {
  height: 10px;
}

/deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  text-align: center;
}

.wrap {
  background: #fff;

  .el-select,
  .el-input {
    width: 100%;
  }
  .tinymce-container {
    width: 765px !important;
  }
  .content {
    position: relative;
    padding: 20px;
margin-bottom: 30px;
    position: relative;
    .btnBoxStyle {
      .btnLast {
        position: absolute;
        right: 58%;
        z-index: 15;
      }
      .btnNext {
        position: absolute;
        right: 51%;
        z-index: 15;
      }
    }

    //遮罩
    .transparentBox {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .chen-image-uploader {
      .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
      }
      .upload-image {
        width: 90px;
        height: 90px;
        display: block;
      }
    }
    .payinput-width {
      width: 70px;
    }
    .buyer-width {
      width: 100px;
    }
    .addPayList {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      margin: 0 0 30px 420px;
    }
    .reducePayList {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      margin-left: 30px;
    }
    .submit-state {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .state-icon {
        font-size: 60px;
        margin-top: -100px;

        .el-icon-success {
          color: #55c176;
        }
      }
      .state-msg {
        font-weight: bold;
        font-size: 18px;
        margin-top: 0px;
      }
      .state-desc {
        font-weight: bold;
        font-size: 15px;
        margin-top: 18px;
        margin-bottom: 30px;
      }
    }
  }
  .input-width {
    width: 100%;
  }
  .input-width-table {
    width: 100%;
    margin-top: 20px;
  }
  .capital {
    margin-top: 15px;
  }
}

.el-form-item__label {
  font-size: 15px;
  padding: 0;
}

/deep/ .el-table__row {
  .el-form-item {
    .el-form-item__content {
      margin-left: 0px !important;
    }
  }
}
</style>
<style lang="less">
.chen-image-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      top: -8px;
      left: 0;
    }
    .num {
      bottom: -8px;
      left: 0;
    }
  }
}
.tag {
  color: #dc143c;
  font-size: 14px;
}
</style>
