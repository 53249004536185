var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "150px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "BidReqRemark",
                attrs: { label: "竞卖说明：", prop: "reqRemark" },
              },
              [
                _c("tinymce-editor", {
                  staticStyle: { width: "90%" },
                  attrs: { value: _vm.ruleForm.autionDetails },
                  on: {
                    input: function ($event) {
                      _vm.ruleForm.autionDetails = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "btnBoxStyle" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnLast",
                    attrs: { type: "primary" },
                    on: { click: _vm.last },
                  },
                  [_vm._v("上一步")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnNext",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.next("ruleForm")
                      },
                    },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }