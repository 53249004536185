<template>
  <div>
    <div class="headTitle">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/User/MyBidding' }"
          ><span>竞卖管理</span></el-breadcrumb-item
        >
          <el-breadcrumb-item>
          <span>{{breadcrumb}}</span>     
          </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="headTips"  v-if=" this.$route.query.isEdit == 'true' && this.RemarkShow!=='' && this.RemarkShow!==undefined &&
         this.$route.query.isHistory !== 'true' &&
         this.$route.query.isExamine !== 'true'">
      <div class="tips">审核通知：</div>

      <div class="text">{{RemarkShow}}</div>
    </div>
    <div class="bidding-container">
      <!-- {{ ruleFormData }} //////bidId{{ bidId }} {{ typeof bidId }}////alastid -->
      <!-- {{ lastid }} {{ typeof lastid }} -->
      <div class="stepNav">
        <stepNav :navData="stepManage.data" :stepNum="stepManage.stepNum" />
          <el-button
          class="el-btn"
          size="small"
          type="primary"
          @click="auditRecord"
          v-if="this.$route.query.isEdit == 'true'"
          ><span>点击查看审核记录</span></el-button
        >
      </div>
      <BiddingInfo
        v-if="stepManage.stepNum == 0"
        :isbidId="isbidId"
        :lastid="lastid"
        :ruleForm="ruleFormData"
        :examineShow="examineShow"
        @nextShow="nextShow"
        @next="Step1Next"
        @getBidId="getBidId"
      ></BiddingInfo>
      <BiddingExplain
        v-if="stepManage.stepNum == 1"
        :bidId="bidId"
        :ruleForm="ruleFormData"
        :examineShow="examineShow"
        @nextShow="nextShow"
        @lastShow="lastShow"
        @addNext="addStepNext"
        @next="Step2Next"
        @last="Step2Last"
      ></BiddingExplain>
      <PaymentTerms
        v-if="stepManage.stepNum == 2"
        :ruleForm="ruleFormData"
        :bidId="bidId"
        :examineShow="examineShow"
        @nextShow="nextShow"
        @lastShow="lastShow"
        @addNext="addStepNext"
        @next="Step3Next"
        @last="Step3Last"
      ></PaymentTerms>
      <Shippingterms
        v-if="stepManage.stepNum == 3"
        :ruleForm="ruleFormData"
        :bidId="bidId"
        :examineShow="examineShow"
        @nextShow="nextShow"
        @lastShow="lastShow"
        @next="Step4Next"
        @last="Step4Last"
      ></Shippingterms>
    </div>
    <!-- <div
      class="publish-examineBox"
      v-if="examineShow && $route.query.isHistory !== 'true'"
    > -->
        <div
      class="publish-examineBox"
       v-if="examineShow && $route.query.isHistory !== 'true'"
    >
      <div class="examineBox" ><span class="examineText">审核信息</span></div>

      <div class="examine-middle">
        <el-form :model="examineForm" ref="examineForm">
          <el-form-item class="form-item" prop="radio">
            <el-radio-group
              v-model="examineForm.radio"
              @change="radioChange(examineForm.radio)"
            >
              <el-radio label="P">审核通过</el-radio>
              <el-radio label="F">审核不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="form-item" prop="textarea">
            <el-input
              class="examineInput"
              type="textarea"
              :rows="7"
              :placeholder="holder"
              v-model="examineForm.textarea"
              maxlength="300"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <div>
        <el-button class="examine" type="primary" @click="examineClick"
          >提交</el-button
        >
      </div>
      <!-- <div class="submit-state">
        <div class="state-icon">
          <i class="el-icon-success"></i>
        </div>
        <p class="state-msg">提交成功</p>
        <p class="state-desc">
          您的竞卖信息已提交成功，等待平台审核，请及时查询
        </p>
        <router-link to="./MyBidding">
          <el-button plain>查看我的竞卖</el-button>
        </router-link>
      </div> -->
    </div>
    <el-dialog :visible.sync="dialogTableVisible" width="1200px">
      <div class="examineBox-dialog">
        <span class="examineText-dialog">审核记录</span>
      </div>
      <el-table
        :data="auditRecordData"
        border
        :header-cell-style="{ 'text-align': 'center', background: '#f6f7fb' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          property="createTime"
          label="发布时间"
          width="200"
        ></el-table-column>
        <el-table-column
          property="createName"
          label="发布人"
          width="200"
        ></el-table-column>
        <el-table-column
          property="approveTime"
          label="审核时间"
          width="200"
        ></el-table-column>
        <el-table-column property="userName" label="审核人"></el-table-column>
        <el-table-column property="approveStatus" label="审核状态">
          <template slot-scope="scope">
            {{ scope.row.approveStatus | getApproveStatus }}
          </template>
        </el-table-column>
        <el-table-column
          property="approveRemarks"
          label="原因"
        ></el-table-column>
      </el-table>

      <div class="dialog-foot">
        <div class="foot-left">
          <el-button
            type="primary"
            size="small"
            @click="dialogTableVisible = false"
            >返回列表</el-button
          >
        </div>
        <div class="foot-right">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :total="auditRecordData.length"
            :page-size="pageSize"
            layout=" pager,next, jumper"
            style="float: left"
            background
            ref="pageGroup"
            next-text="下一页"
          >
          </el-pagination>
          <!-- <el-button class="dialogNext" size="small" @click="dialogNext"
            >下一页</el-button
          > -->
          <el-button style="hight: 50px" size="small" type="primary"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import stepNav from "@/modules/User/component/step-nav.vue";
import BiddingExplain from "./components/BiddingExplain.vue";

import BiddingInfo from "./components/BiddingInfo.vue";
import PaymentTerms from "./components/PaymentTerms.vue";

import Shippingterms from "./components/Shippingterms.vue";

import {
  myAuthority,
  getPassAudit,
  getRejectAudit,
  AUAuditList,
} from "../../../api";

export default {
  watch: {
    $route: function (to) {
      console.log(to);
      if (Object.keys(to.query).length == 0) {
        this.initializationData();
        this.examineShow = false;
      }
    },
  },
  filters: {
    getApproveStatus(val) {
      //  0：无需审核 1:待审核 2：审核通过 3：审核不通过
      let statusText = "";

      if (!val) return statusText;

      switch (val) {
        case "0":
          statusText = "无需审核";
          break;
        case "1":
          statusText = "待审核";
          break;
        case "2":
          statusText = "审核通过";
          break;
        case "3":
          statusText = "审核不通过";
          break;
      }
      return statusText;
    },
  },
  created() {
    this.AUAuditList()

    if (this.$route.query.isEdit === "true") {
      this.breadcrumb='修改竞卖'
      if (this.$route.query.isExamine == "true") {
        this.examineShow = true;
        this.breadcrumb='竞卖审核'
      }
      if (this.$route.query.isHistory == "true") {
        this.breadcrumb='查看详情'
      }
    }
  },
  components: {
    stepNav,
    BiddingInfo,
    BiddingExplain,
    PaymentTerms,
    Shippingterms,
  },

  data() {
    return {
      breadcrumb:'发布竞卖',
      RemarkShow:'',//审核内容
      currentPage: 1,
      pageSize: 5,
      total:'',
      dialogTableVisible: false,
      examineShow: false,
      holder: "请选择审核信息",
      auditRecordData: [],
      examineForm: {
        textarea: "",
        radio: "",
      },
      stepManage: {
        data: ["1.竞卖信息", "2.竞卖说明", "3.付款条款", "4.发货条款"],
        stepNum: 0,
      },
      lastid: 0,
      isbidId: 0,
      bidId: 0,
      ruleFormData: {
        id: "",
        contractNumber: "", //合同编号
        picUrl: "", //产品图片
        prodName: "", //产品名称
        dtsCategoryId: "", //产品类型
        prodModels: "", //规格型号
        amount: "", //数量
        units: "", //单位
        upPrice: "", //起拍价
        bottomPrice: "", //最低价
        failTactics: "", //降价策略
        startTime: "", //开始时间
        continueTime: "", //持续时间
        autionDetails: "", //竞卖说明
        contractinfono: "",
        orderProcess: "", //订单模式
        payMode: [], //付款方式
        bankBillTerm: "", //承兑汇票期限
        testlist: [],
        invoiceType: "",
        //付款分期表格
        payDeliverRateFkList: [
          // {
          //   termIndex: "",
          //   rate: "",
          //   date: "", //分期付款开始时间
          //   payMode: "",
          //   type: "FK",
          // },
        ],
        //交货分期表格
        payDeliverRateJhList: [
          // {
          //   termIndex: "",
          //   rate: "",
          //   date: "", //交货付款开始时间
          //   // payMode: "",
          //   type: "JH",
          // },
        ],

        arbAdditionInfo: {
          fqTermIndex: "",
          type: "AU", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
          yhhpPercentage: "", //银行汇票比例
        },
      },
    };
  },
  methods: {
     nextShow() {
      this.stepManage.stepNum++;
    },
    lastShow() {
      this.stepManage.stepNum--;
    },
    getBidId(id) {
      if (typeof id !== Object) {
        this.bidId = id;
        this.isbidId = this.bidId;
      }
      if (typeof id == Object) {
        this.bidId = 0;
        this.isbidId = 0;
      }

      this.stepManage.stepNum++;
    },
    addStepNext() {
      this.stepManage.stepNum++;
    },
    Step1Next(data, id) {
      this.ruleFormData = data;
    },

    Step2Next(data) {
      // this.stepManage.stepNum++;
      this.ruleFormData = data;
      console.log("ruleForm2", this.ruleFormData);
    },

    Step3Next(data) {
      this.ruleFormData = data;
      // this.stepManage.stepNum++;
    },

    Step4Next() {
      // this.stepManage.stepNum++;
    },
    Step2Last(data, id) {
      this.stepManage.stepNum--;
      this.lastid = id;
      console.log("data2", data);
      // this.ruleFormData = data;
    },
    Step3Last(data) {
      this.stepManage.stepNum--;
      // this.ruleFormData = data;
    },
    Step4Last(data) {
      //  this.ruleFormData = data;

      this.stepManage.stepNum--;
    },

    initializationData() {
       this.breadcrumb='发布竞卖',
      this.RemarkShow='';
      this.examineShow = false;
      this.stepManage.stepNum = 0;
      this.lastid = 0;
      this.isbidId = 0;
      this.bidId = 0;
      this.deliverSetList = false;
      this.paySetList = false;
      this.FkArrange = "";
      this.newListFk = [];
      this.newListJh = [];
      this.pageStete = "publish";
      this.contractTemplate = "";
      this.contractDialogVisible = false;
      //   this.contractList = [];
      this.contractInfo = [];
      this.ruleFormData = {
        id: "",
        contractNumber: "", //合同编号
        picUrl: "", //产品图片
        prodName: "", //产品名称
        dtsCategoryId: "", //产品类型
        prodModels: "", //规格型号
        amount: "", //数量
        units: "", //单位
        upPrice: "", //起拍价
        bottomPrice: "", //最低价
        failTactics: "", //降价策略
        startTime: "", //开始时间
        continueTime: "", //持续时间
        autionDetails: "", //竞卖说明
        contractinfono: "",
        orderProcess: "", //订单模式
        payMode: [], //付款方式
        bankBillTerm: "", //承兑汇票期限
        testlist: [],
        //付款分期表格
        payDeliverRateFkList: [
          // {
          //   termIndex: "",
          //   rate: "",
          //   date: "", //分期付款开始时间
          //   payMode: "",
          //   type: "FK",
          // },
        ],
        //交货分期表格
        payDeliverRateJhList: [
          // {
          //   termIndex: "",
          //   rate: "",
          //   date: "", //交货付款开始时间
          //   // payMode: "",
          //   type: "JH",
          // },
        ],

        arbAdditionInfo: {
          fqTermIndex: "",
          type: "AU", //竞卖
          taxRate: "", // 使用税率
          invsStatus: "", // 开票时间
          paySet: "", //付款安排
          deliverSet: "", //交付安排
          deliverMode: "", // 交货方式
          packageMode: "", //包装方式
          checkMode: "", //验收条件
          qualityMode: "", //质保方式
          qualityTerm: "", //质保的日期
          paySetPort: "", // 付款安排数据
          deliverSetPort: "", // 交付安排数据

          invsDay: "", //付款后开发票日期
          deliverModeDay: "", //买家自提的日期
          bankBillTerm: "", //承兑汇票期限
          payMode: "",
          //------------------------
          paySetDay: "", //付款安排一次性付款日期

          //---------------------------
          deliverSetDay: "", //交货安排一次性交货日期

          //-----------------------------
          payPercent: "", // 付款占比
          yhzzPercentage: "", //银行转账百分比
          cdhpPercentage: "", //承兑汇票百分比
          yhhpPercentage: "", //银行汇票比例
        },
      };
      this.publishForm = {
        productName: "",
        categoryId: "",
        productSpec: "",
        maxPrice: "",
        reqNum: "",
        unit: "",
        picUrl: "",
        startTime: "",
        validDate: "",
        dateTime: "",
        date: "",
        time: "",
        reqRemark: "",
        addressId: "",
        deliverDay: "",
        stepPrice: "",
        // forceShow: '',
        contractInfo: "",
        orderProcess: "",
        payMode: "",
        bankBillTerm: "",
        taxRate: "", // 使用税率
        invsStatus: "", // 票据时间
        paySet: "", //付款安排
        paySetPort: "", // 付款安排数据
        invsDay: "", //付款后开发票日期
      };
      this.productDetail = null;
      // this.paymentmethod.orderProcess = "";
      // this.productDetail.payMode = "";
      // this.productDetail.bankBillTerm = "";
      this.showState = false;
    },
     radioChange(value) {
      if (value == "P") {
        this.holder = "请输入审核通过原因";
      } else if (value == "F") {
        this.holder = "请输入审核不通过的原因";
      }
    },
    //审核按钮
    examineClick() {
      if (this.examineForm.radio == "")
        return this.$message.info("请选择审核信息");
      if (this.examineForm.radio == "F" && this.examineForm.textarea == "")
        return this.$message.info("审核不通过原因不得为空");
     if (localStorage.getItem('userPermission').indexOf('sell:examine') ==-1) {
        return this.$message.info("暂无审核权限，请联系管理员");
      }
      if (this.examineForm.radio == "P") {
        this.getPassAudit();
      } else if (this.examineForm.radio == "F") {
        this.getRejectAudit();
      }
    },
    getPassAudit() {
      getPassAudit({
        businessId:
          this.isbidId == 0 && this.isEdit !== true
            ? this.$route.query.id
            : this.isbidId, //主键id
        status: "AU", // 订单类型 AU竞卖订单 RE 竞买订单  BI 竞标订单
        approveStatus: this.examineForm.radio, // A审核中 P审核通过 F审核拒绝
        approveRemarks: this.examineForm.textarea, //审核备注
      }).then((res) => {
        if (res.errno === 0) {
          this.$router.push({
            path: "/User/auditResult",
            query: { state: true, status: "AU" },
          });
        }
      });
    },
    getRejectAudit() {
      getRejectAudit({
        businessId:
          this.isbidId == 0 && this.isEdit !== true
            ? this.$route.query.id
            : this.isbidId, //主键id
        status: "AU", // 订单类型 AU竞卖订单 RE 竞买订单  BI 竞标订单
        approveStatus: this.examineForm.radio, // A审核中 P审核通过 F审核拒绝
        approveRemarks: this.examineForm.textarea, //审核备注
      }).then((res) => {
        console.log(2);
        if (res.errno === 0) {
          this.$router.push({
            path: "/User/auditResult",
            query: { state: false, status: "AU" },
          });
        }
      });
    },
    //审核历史记录
    auditRecord() {
      this.dialogTableVisible = true;

      this.$nextTick(() => {
        document.getElementsByClassName(
          "el-pagination__jump"
        )[0].childNodes[0].nodeValue = "到";
      });

      // this.AUAuditList();
    },
    //审核历史列表
    AUAuditList() {
      AUAuditList({
        businessId:
          this.isbidId == 0 && this.isEdit !== true
            ? this.$route.query.id
            : this.isbidId, //主键id
        operationName: "RELEASE_AU_ORDER",
        pageNum: this.currentPage,
        pageSize: 10,
      }).then((res) => {
        console.log(2);
        if (res.errno === 0) {
       this.auditRecordData = res.data.list;
          this.total=res.data.total
          this.AUAuditListTotal()
      
        }
      });
    },
      AUAuditListTotal() {
      AUAuditList({
        businessId:
          this.isbidId == 0 && this.isEdit !== true
            ? this.$route.query.id
            : this.isbidId, //主键id
        operationName: "RELEASE_AU_ORDER",
        pageNum: this.total,
        pageSize:this.currentPage,
      }).then((res) => {
        console.log("total");
        if (res.errno === 0) {
          let dataList = res.data.list;
          if (res.data.list.length !== 0) {
            this.ramark = JSON.parse(JSON.stringify(dataList));
            this.RemarkShow = this.ramark.pop().approveRemarks;
          }
        }
      });
    },

    // //获取用户是否有审核权限
    // async getMyAuthority() {
    //   if (this.$route.query.isExamine == "true") {
    //     console.log("审核页面");
    //     this.examineShow = true;
    //   }

    //   // let res = await myAuthority({
    //   //   isPermissions: "client:bid:push",
    //   // });
    //   // if (res.errno == 0) {

    //   //   this.examineShow = res.data;
    //   // }
    // },
    dialogNext() {
      this.$refs.pageGroup.next();
    },
    dialogJumper() {
      this.$refs.pageGroup.jumper();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.AUAuditList();
    },
  },
};
</script>

<style lang="less" scoped>
div::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
/deep/ .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
}
/deep/ .el-pagination__jump {
  margin-right: 20px;
}
/deep/ .el-pagination__next {
  margin-left: 10px;
}

/deep/ .btn-next {
  width: 60px;
  height: 30px;
  background-color: #409eff !important;
  color: #fff !important;
}

.dialog-foot {
  margin-top: 30px;
  height: 40px;
  .foot-left {
    float: left;
  }
  .foot-right {
    float: right;
  }
}
.examineBox-dialog {
  border-left: 3px solid #03a0fc;
  padding-left: 10px;

  margin: -30px 0 30px 0;
  font-size: 16px;
  font-weight: 700;
  .examineText-dialog {
    display: block;
    // margin-top: 35px;
  }
}
.publish-examineBox {
  background: #fff;
  padding: 20px 0 35px 0;
  margin-top: 20px;
  .examine-middle {
    padding: 50px 60px 70px 60px;
    .examineInput {
      display: block;
      margin-top: 30px;
    }
  }

  .examineBox {
    border-left: 3px solid #03a0fc;
    padding-left: 10px;
    margin-left: 35px;
    font-size: 16px;
    font-weight: 700;
    .examineText {
      display: block;
      // margin-top: 35px;
    }
  }
  .examine {
    // position: absolute;
    margin-left: 46%;
    width: 100px;
  }
}

.headTitle {
  span {
    font-size: 18px;
  }
  margin-top: -10px;
  // position: absolute;
  // top: -35px;
}
.headTips {
  margin-top: 20px;
  height: 40px;
  background-color: #f96060;
  border-radius: 5px;
  color: #fff;
  line-height: 40px;
  padding: 0 30px;
  font-size: 15px;
  .tips {
    display: inline-block;
    overflow-x: auto;

    white-space: nowrap;
  }
  .text {
    display: inline-block;
    width: 1400px;
    // background-color: skyblue;
    overflow-x: auto;

    white-space: nowrap;
    // overflow-x:scroll
  }
}
.bidding-container {
  background: #fff;
  padding-bottom: 20px;
  margin-top: 20px;
  position: relative;
  .headTips {
    span {
      font-size: 18px;
    }
    position: absolute;
    top: -35px;
  }
  .stepNav {
    margin-left: 28px;
    padding: 18px 0;
    position: relative;

    .el-btn {
      position: absolute;
      font-size: 15px;
      text-align: center;
      width: 150px;
      height: 45px;
      right: 80px;
      top: 50px;
      display: inline-block;
    }
  }
  .form-content {
    padding-top: 20px;
    margin: 0 auto;
    width: 90%;
    // el-date-picker el-popper has-time
    .input-width {
      width: 300px;
      font-size: 12px;
    }
    .input-width-small {
      width: 60px;
    }
    .table-input-width {
      width: 250px;
      margin-top: 20px;
    }
    .capital {
      margin-top: 15px;
    }
    .form-item {
      margin-right: 0;
      margin-bottom: 30px;
      width: 50%;

      .hint {
        position: absolute;
        top: -22px;
        color: #999;
      }
    }
    .avatar {
      width: 148px;
      height: 148px;
      display: block;
    }
    .select-style {
      width: 300px;
      .el-select-dropdown__item {
        height: auto;
      }
    }
  }
}
.contract-box {
  display: flex;

  .contract-left {
    flex: 1;
    position: relative;

    span {
      position: absolute;
    }
    .name {
      top: -8px;
      left: 0;
    }
    .num {
      bottom: -8px;
      left: 0;
    }
  }
}
.product-content {
  margin-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .upbtn {
      display: flex;

      .upload-demo {
        margin: 0px 8px;
      }
    }
  }
}
.product-list {
  .input-width {
    width: 280px;
  }
}
/deep/.el-form-item__label {
  font-size: 12px;
  padding: 0;
}

/deep/.mce-tinymce {
  box-shadow: none;
}
</style>
