var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.examineShow
          ? _c("div", { staticClass: "transparentBox" })
          : _vm._e(),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "150px",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "包装方式：",
                          prop: "arbAdditionInfo.packageMode",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select-style",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value: _vm.ruleForm.arbAdditionInfo.packageMode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.arbAdditionInfo,
                                  "packageMode",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.arbAdditionInfo.packageMode",
                            },
                          },
                          _vm._l(
                            _vm.paymentmethod.packageModeData,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "验收条件：",
                          prop: "arbAdditionInfo.checkMode",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select-style",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value: _vm.ruleForm.arbAdditionInfo.checkMode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.arbAdditionInfo,
                                  "checkMode",
                                  $$v
                                )
                              },
                              expression: "ruleForm.arbAdditionInfo.checkMode",
                            },
                          },
                          _vm._l(
                            _vm.paymentmethod.checkModeData,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "交货方式：",
                          prop: "arbAdditionInfo.deliverMode",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.arbAdditionInfo.deliverMode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.arbAdditionInfo,
                                  "deliverMode",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.arbAdditionInfo.deliverMode",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "SH" } }, [
                              _vm._v("供应商送货"),
                            ]),
                            _c("el-radio", { attrs: { label: "ZT" } }, [
                              _vm._v("买家自提"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-invsStatus",
                        style: {
                          color:
                            _vm.ruleForm.arbAdditionInfo.deliverMode == "ZT"
                              ? "#000"
                              : "#c0c4cc",
                        },
                        attrs: { prop: "arbAdditionInfo.deliverModeDay" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "14px" } },
                          [
                            _vm._v(" 卖方通知提货之日起【"),
                            _c("el-input", {
                              staticClass: "payinput-width",
                              attrs: {
                                size: "mini",
                                disabled:
                                  _vm.ruleForm.arbAdditionInfo.deliverMode ==
                                  "ZT"
                                    ? false
                                    : true,
                              },
                              model: {
                                value:
                                  _vm.ruleForm.arbAdditionInfo.deliverModeDay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.arbAdditionInfo,
                                    "deliverModeDay",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.arbAdditionInfo.deliverModeDay",
                              },
                            }),
                            _vm._v("】个工作日内提货 "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "质保方式：",
                          prop: "arbAdditionInfo.qualityMode",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.arbAdditionInfo.qualityMode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.arbAdditionInfo,
                                  "qualityMode",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.arbAdditionInfo.qualityMode",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "GZB" } }, [
                              _vm._v("供应商质保"),
                            ]),
                            _c("el-radio", { attrs: { label: "ORTHER" } }, [
                              _vm._v("其他"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-invsStatus",
                        style: {
                          color:
                            _vm.ruleForm.arbAdditionInfo.qualityMode == "GZB"
                              ? "#000"
                              : "#c0c4cc",
                        },
                        attrs: { prop: "arbAdditionInfo.qualityTerm" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "14px" } },
                          [
                            _vm._v(" 通过验收之日起【"),
                            _c("el-input", {
                              staticClass: "payinput-width",
                              attrs: {
                                size: "mini",
                                disabled:
                                  _vm.ruleForm.arbAdditionInfo.qualityMode ==
                                  "GZB"
                                    ? false
                                    : true,
                              },
                              model: {
                                value: _vm.ruleForm.arbAdditionInfo.qualityTerm,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.arbAdditionInfo,
                                    "qualityTerm",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.arbAdditionInfo.qualityTerm",
                              },
                            }),
                            _vm._v("】年 "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item",
                        attrs: {
                          label: "交货安排：",
                          prop: "arbAdditionInfo.deliverSet",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.deliverSetMethod(
                                  _vm.ruleForm.arbAdditionInfo.deliverSet
                                )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.arbAdditionInfo.deliverSet,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.arbAdditionInfo,
                                  "deliverSet",
                                  $$v
                                )
                              },
                              expression: "ruleForm.arbAdditionInfo.deliverSet",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "QK" } }, [
                              _vm._v("一次性交货"),
                            ]),
                            _c("el-radio", { attrs: { label: "FQ" } }, [
                              _vm._v("分期交货"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.deliverSet == "FQ"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "分期交货：",
                              prop: "arbAdditionInfo.fqJhTermIndex",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input-width",
                              attrs: {
                                placeholder: "1-24期",
                                size: "mini",
                                onkeyup: "value=value.replace(/[^\\d]/g,'')",
                                maxlength: "2",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.inputChange(
                                    _vm.ruleForm.arbAdditionInfo.fqJhTermIndex
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.ruleForm.arbAdditionInfo.fqJhTermIndex,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm.arbAdditionInfo,
                                    "fqJhTermIndex",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.arbAdditionInfo.fqJhTermIndex",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _vm.deliverSetList &&
                    _vm.ruleForm.arbAdditionInfo.deliverSet == "FQ"
                      ? _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "92%",
                              margin: "0 0 20px 70px",
                            },
                            attrs: {
                              data: _vm.ruleForm.payDeliverRateJhList,
                              "highlight-current-row": "",
                              border: "",
                              align: "center",
                              "row-style": { height: 0 + "px" },
                              "cell-style": { padding: 0 + "px" },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "termIndex", label: "期数" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " 第" +
                                            _vm._s(scope.row.termIndex) +
                                            "期 "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1929236414
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "rate", label: "交货比例" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "payDeliverRateJhList." +
                                                scope.$index +
                                                ".rate",
                                              rules: _vm.rules.JHrate,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "input-width-table",
                                              attrs: {
                                                size: "mini",
                                                oninput:
                                                  "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3').replace(/^\\./g, '')",
                                              },
                                              model: {
                                                value: scope.row.rate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "rate",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.rate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1709352567
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "date", label: "支付时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "payDeliverRateJhList." +
                                                scope.$index +
                                                ".date",
                                              rules: _vm.rules.JHdate,
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "input-width-table",
                                              attrs: {
                                                size: "mini",
                                                type: "date",
                                                "value-format": "yyyy-MM-dd",
                                                "picker-options": _vm.option,
                                                placeholder: "请选择开始时间",
                                              },
                                              model: {
                                                value: scope.row.date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "date",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.date",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                947450592
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.deliverSet == "QK"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "一次性交货：",
                              prop: "arbAdditionInfo.deliverSetPort",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", size: "small" },
                                model: {
                                  value:
                                    _vm.ruleForm.arbAdditionInfo.deliverSetPort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.arbAdditionInfo,
                                      "deliverSetPort",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.arbAdditionInfo.deliverSetPort",
                                },
                              },
                              _vm._l(
                                _vm.paymentmethod.arrangementDelivery,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.deliverSet == "QK" &&
                    _vm.ruleForm.arbAdditionInfo.deliverSetPort == "QD"
                      ? _c(
                          "el-form-item",
                          { attrs: { prop: "arbAdditionInfo.deliverSetDay" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "font-size": "14px" } },
                              [
                                _vm._v(" 签订合同之日起【"),
                                _c("el-input", {
                                  staticClass: "payinput-width",
                                  attrs: { size: "mini" },
                                  model: {
                                    value:
                                      _vm.ruleForm.arbAdditionInfo
                                        .deliverSetDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm.arbAdditionInfo,
                                        "deliverSetDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.arbAdditionInfo.deliverSetDay",
                                  },
                                }),
                                _vm._v("】个工作日内 "),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 20 } },
                  [
                    _vm.ruleForm.arbAdditionInfo.deliverSet == "QK" &&
                    _vm.ruleForm.arbAdditionInfo.deliverSetPort == "SK"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "form-item",
                            attrs: {
                              label: "验收合格之后：",
                              prop: "arbAdditionInfo.deliverSetDay",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "font-size": "14px" } },
                              [
                                _vm._v(" 收到全部贷款之日起【"),
                                _c("el-input", {
                                  staticClass: "payinput-width",
                                  attrs: { size: "mini" },
                                  model: {
                                    value:
                                      _vm.ruleForm.arbAdditionInfo
                                        .deliverSetDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm.arbAdditionInfo,
                                        "deliverSetDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.arbAdditionInfo.deliverSetDay",
                                  },
                                }),
                                _vm._v("】个工作日内 "),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btnBoxStyle" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnLast",
                    attrs: { type: "primary" },
                    on: { click: _vm.last },
                  },
                  [_vm._v("上一步")]
                ),
                this.examineShow !== true
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btnNext",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.next("ruleForm")
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            this.$route.query.id == "" ||
                              this.$route.query.id == undefined
                              ? "立即发布"
                              : "修改"
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showState
          ? _c(
              "div",
              { staticClass: "submit-state" },
              [
                _vm._m(0),
                _c("p", { staticClass: "state-msg" }, [_vm._v("提交成功")]),
                _c("p", { staticClass: "state-desc" }, [
                  _vm._v(" 您的竞卖信息已提交成功，等待平台审核，请及时查询 "),
                ]),
                _c(
                  "router-link",
                  { attrs: { to: "./MyBidding" } },
                  [
                    _c("el-button", { attrs: { plain: "" } }, [
                      _vm._v("查看我的竞卖"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "state-icon" }, [
      _c("i", { staticClass: "el-icon-success" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }